<mat-card class="fx-card fx-fill-height">
	<mat-toolbar
		class="fx-card-toolbar fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-8"
	>
		<span class="font-semibold text-2xl mr-4">{{ 'WORKFLOW.NAME' | translate }}</span>

		<button [matMenuTriggerFor]="dataMenu" mat-flat-button>
			{{ selectedView.getValue().name | translate }}
			<mat-icon fontSet="mdi" fontIcon="mdi-menu-down"></mat-icon>
		</button>


		@if (hiddenUser()) {
			<mat-slide-toggle
				style="width: 180px"
				(toggleChange)="hideForced = !hideForced"
				[checked]="hideForced"
			>
				{{ "FORCE_BILLED" | translate }}
			</mat-slide-toggle>
		}

		<span class="fx-hide-lt-sm fx-grow-1"></span>
		<span class="text-base font-semibold">{{ currentDate }}</span>
		<span class="fx-grow-1"></span>



		@if (profile['scheduleExam'] !== 'NONE') {
			<button
				(click)="createExamWithPayment()"
				mat-raised-button color="primary">
				<mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
				<span class="fx-hide-lt-md"> {{ "NEW_EXAM" | translate }}</span>
			</button>
		}






		<button mat-icon-button [matMenuTriggerFor]="extra">
			<mat-icon fontIcon="mdi-tune" fontSet="mdi"></mat-icon>
		</button>

		<mat-menu #extra="matMenu">

			<button [disabled]="profile?.printTaskList === 'NONE'" (click)="generateTasksList()" mat-menu-item>
				<mat-icon fontIcon="mdi-checkbox-marked-circle-auto-outline" fontSet="mdi"></mat-icon>
				{{ 'GENERATE_TASK_LIST' | translate }}
			</button>

			<button [disabled]="profile?.scheduleExam === 'NONE'"
					(click)="$event.stopPropagation(); queryPacs()"
					mat-menu-item>
				<mat-icon

					fontIcon="mdi-database-search-outline"
					fontSet="mdi"></mat-icon>
				&nbsp;{{
					'QUERY_PACS' | translate
				}}
			</button>

			<button (click)="exportExcel()" mat-menu-item>
				<mat-icon
					fontSet="mdi"
					fontIcon="mdi-file-excel"
				></mat-icon>
				{{ "EXPORT_EXCEL" | translate }}
			</button>
            <mat-divider></mat-divider>

            <button (click)="searchService.toggleSearchView()" mat-menu-item>
                <mat-icon
                        fontSet="mdi"
                        fontIcon="mdi-pencil-ruler"
                ></mat-icon>
                {{ (searchService.newSearchView() ? "search.old": "search.new") | translate }}
            </button>

		</mat-menu>


		<mat-menu #dataMenu="matMenu" [overlapTrigger]="false" class="ft-view-menu">
			@for (view of views; track $index) {
				<div mat-menu-item (click)="selectedView.next(view)">
					<div
						class="fx-layout-row-nowrap fx-content-space-between fx-items-center"
						style="width: 148px"
					>
						<span>{{ view.name | translate }}</span>

						<span class="fx-grow-1"></span>

						@if (view.id !== 0) {
							<button (click)="editView(view)" mat-icon-button color="primary">
								<mat-icon
									style="color: inherit"
									fontIcon="mdi-pencil"
									fontSet="mdi"
								></mat-icon>
							</button>
							<button (click)="deleteView(view)" mat-icon-button color="warn">
								<mat-icon
									style="color: inherit"
									fontIcon="mdi-delete"
									fontSet="mdi"
								></mat-icon>
							</button>
						}
					</div>
				</div>
			}
			<div mat-menu-item>
				<button mat-raised-button class="w-full"
						(click)="$event.preventDefault();$event.stopImmediatePropagation();addNewView()">
					<mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
					{{ "NEW_TABLE_VIEW" | translate }}
				</button>
			</div>

		</mat-menu>

	</mat-toolbar>


    <div *ngIf="searchService.newSearchView(); else searchTemplate"></div>
	<ng-template #searchTemplate>
		<div class="flex flex-col md:items-start lg:flex-row lg:gap-1 lg:p-3 lg:flex-wrap lg:items-end lg:fx-search-area" [formGroup]="filterForm">
			@if (profile?.manageExam === 'ALL') {
				<mat-form-field class="fx-grow-1" subscriptSizing="dynamic">
					<mat-label>{{ "RADIOLOGIST" | translate }}</mat-label>
					<mat-select formControlName="physicianId">
						<mat-option [value]="null">{{ "ALL" | translate }}</mat-option>
						@for (option of physicians; track $index) {
							<mat-option [value]="option.id">{{ option["fullName"] }}</mat-option>
						}
					</mat-select>
				</mat-form-field>
			}

			<div>
				<h5 style="margin: 6px 0">{{ "MODALITY" | translate }}</h5>
				<mat-button-toggle-group
					aria-label="modality"
					formControlName="modality"
					multiple
					name="modality"
				>
					@for (modality of modalities; track $index) {
						<mat-button-toggle [value]="modality">{{ modality }}</mat-button-toggle>
					}
				</mat-button-toggle-group>
			</div>

			<div>
				<h5 style="margin: 6px 0">{{ "REPORT_STATUS" | translate }}</h5>
				<mat-button-toggle-group
					aria-label="Report status"
					formControlName="reportStatus"
					multiple
					name="reportStatus"
				>
					@for (rule of columnsFormattingRules | filterBy: 'REPORTING_STATUS';
						track $index) {
						<mat-button-toggle
							[matTooltip]="rule.primaryFormatValue | translate"
							[value]="rule.primaryFormatValue"
						>
							@switch (rule.formattingStyle?.displayMode) {
								@case ('ICON') {
									<mat-icon
										[fontIcon]="rule.formattingStyle?.icon"
										[ngStyle]="{
              background:
                'linear-gradient(45deg, black, ' +
                rule.formattingStyle?.color +
                ', white)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }"
										fontSet="mdi"
									></mat-icon>
								}
								@default {
									<span [style.color]="rule.formattingStyle?.color">{{
											rule.primaryFormatValue | translate
										}}</span>
								}
							}
						</mat-button-toggle>
					}
				</mat-button-toggle-group>
			</div>

			<div>
				<h5 style="margin: 6px 0">{{ "PATIENT_STATUS" | translate }}</h5>
				<mat-button-toggle-group
					aria-label="patient status"
					formControlName="patientStatus"
					multiple
					name="patientStatus"
				>
					@for (rule of columnsFormattingRules | filterBy: 'PATIENT_STATUS';
						track $index) {
						<mat-button-toggle
							[matTooltip]="rule.primaryFormatValue | translate"
							[value]="rule.primaryFormatValue"
						>
							@switch (rule.formattingStyle?.displayMode) {
								@case ('ICON') {
									<mat-icon
										[fontIcon]="rule.formattingStyle?.icon"
										[ngStyle]="{
              background:
                'linear-gradient(45deg, black, ' +
                rule.formattingStyle?.color +
                ', white)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }"
										fontSet="mdi"
									></mat-icon>
								}
								@default {
									<span [style.color]="rule.formattingStyle?.color">{{
											rule.primaryFormatValue | translate
										}}</span>
								}
							}
						</mat-button-toggle>
					}
				</mat-button-toggle-group>
			</div>

			@if (generalSetting?.billingRequired && profile?.managePayment) {
				<div>
					<h5 style="margin: 6px 0">
						{{ "PAYMENT_STATUS" | translate }}
					</h5>
					<mat-button-toggle-group
						aria-label="payment status"
						formControlName="paymentStatus"
						multiple
						name="paymentStatus"
					>
						@for (rule of columnsFormattingRules | filterBy: 'PAYMENT_STATUS';
							track $index) {
							<mat-button-toggle
								[matTooltip]="rule.primaryFormatValue | translate"
								[value]="rule.primaryFormatValue"
							>
								@switch (rule.formattingStyle?.displayMode) {
									@case ('ICON') {
										<mat-icon
											[fontIcon]="rule.formattingStyle?.icon"
											[ngStyle]="{
              background:
                'linear-gradient(45deg, black, ' +
                rule.formattingStyle?.color +
                ', white)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }"
											fontSet="mdi"
										></mat-icon>
									}
									@default {
										<span [style.color]="rule.formattingStyle?.color">{{
												rule.primaryFormatValue | translate
											}}</span>
									}
								}
							</mat-button-toggle>
						}
					</mat-button-toggle-group>
				</div>
			}

			<div>
				<h5 style="margin: 6px 0">{{ "PERIOD" | translate }}</h5>
				<mat-button-toggle-group
					#group="matButtonToggleGroup"
					(change)="changeRange($event)"
					aria-label="Period"
					formControlName="period"
					name="period"
				>
					<mat-button-toggle value="TODAY">
						{{ "TODAY" | translate }}
					</mat-button-toggle>
					<mat-button-toggle value="YESTERDAY">
						{{ "YESTERDAY" | translate }}
					</mat-button-toggle>
					<mat-button-toggle value="3D">
						{{ "THREE_DAYS" | translate }}
					</mat-button-toggle>
					<mat-button-toggle value="1W">
						{{ "ONE_WEEK" | translate }}
					</mat-button-toggle>
					<mat-button-toggle value="1M">
						{{ "ONE_MONTH" | translate }}
					</mat-button-toggle>
					<mat-button-toggle value="3M">
						{{ "THREE_MONTHS" | translate }}
					</mat-button-toggle>
					<mat-button-toggle value="OT">
						{{ "ALL" | translate }}
					</mat-button-toggle>
				</mat-button-toggle-group>
			</div>

			@if (group.value === 'OT') {
				<mat-form-field subscriptSizing="dynamic" class="pt-2">
					<mat-date-range-input [rangePicker]="picker">
						<input
							[placeholder]="'FROM' | translate"
							formControlName="startDate"
							matStartDate
							(dateInput)="changePeriod()"
						/>
						<input
							[placeholder]="'TO' | translate"
							formControlName="endDate"
							matEndDate
							(dateInput)="changePeriod()"
						/>
					</mat-date-range-input>
					<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
					<mat-date-range-picker
						#picker
						(closed)="changePeriod()"
					></mat-date-range-picker>
				</mat-form-field>
			}

			<mat-slide-toggle
				class="pt-2"
				formControlName="hasImages">
				{{ "HAS_IMAGES_ONLY" | translate }}
			</mat-slide-toggle>

		</div>
	</ng-template>


	<mat-menu #menu="matMenu" [overlapTrigger]="true" class="columns-menu">
		<div cdkDropList class="ft-drag-list" (cdkDropListDropped)="drop($event)">
			@for (item of availableColumns; track $index) {
				<button
					class="ft-drag-box"
					mat-menu-item
					(click)="$event.stopPropagation();$event.stopImmediatePropagation(); toggleColumn(item)"
					cdkDrag
				>
					<mat-icon
						fontSet="mdi"
						fontIcon="{{
            item?.hidden
              ? 'mdi-checkbox-blank-circle-outline'
              : 'mdi-check-circle'
          }}"
					></mat-icon>
					{{ item?.header | translate }}
				</button>
			} @empty {
				<button mat-menu-item>No data</button>
			}
		</div>
	</mat-menu>

	<mat-menu #menu2="matMenu" [overlapTrigger]="true">
		<div cdkDropList class="ft-drag-list" (cdkDropListDropped)="drop2($event)">
			@for (column of examsColumns; track $index) {
				<button
					class="ft-drag-box"
					mat-menu-item
					(click)="$event.stopPropagation(); toggleColumn2(column)"
					cdkDrag
				>
					<mat-icon
						style="color: #1565c0"
						fontSet="mdi"
						fontIcon="{{
            column.hidden
              ? 'mdi-checkbox-blank-circle-outline'
              : 'mdi-check-circle'
          }}"
					></mat-icon>
					{{ column.header | translate }}
				</button>
			}
		</div>
	</mat-menu>

	<mat-drawer-container [hasBackdrop]="false" class="fx-grow-1">
		<mat-drawer
			#drawer
			[style.marginTop.px]="-1"
			[style.width.px]="348"
			mode="over"
			position="end"
		>
			<ft-table-formatter
				(closeEvent)="drawer.toggle()"
				(saveEvent)="saveTableConfig($event)"
				[tableConfig]="workflowTableConfig"
			></ft-table-formatter>
		</mat-drawer>
		<mat-drawer-content>
			<div class="fx-layout-column fx-fill">
				<div class="fx-overflow-auto fx-grow-1">
					<mat-table
						[dataSource]="dataSource"
						[matSortActive]="sorting.active"
						[matSortDirection]="sorting.direction"
						[trackBy]="trackById"
						class="expandable-rows-table fx-fill-height fx-w-fit"
						matSort
						[matSortDisableClear]="true"
						[multiTemplateDataRows]="true"
					>
						<!-- Nested row-->
						<ng-container matColumnDef="expandedRow">
							<mat-cell
								*matCellDef="let row"
								[attr.cols]="columnsToDisplay.length"
							>
								<div [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'"
									 class="example-element-detail">
									@if (expandedElement) {

										<!--<div class="flex flex-row gap-1">
											@for (element of expandedElement.workflowItems; track $index) {
												<ft-exam-card [workflowItem]="element"></ft-exam-card>
											}
										</div>-->


										<table class="nested-table">
											<thead>
											<tr>
												@for (col of examsColumnsToDisplay; track $index) {
													<th>
														{{ col.header | translate }}
													</th>
												}
												<th>
													<button
														[matMenuTriggerFor]="menu2"
														[style.float]="'right'"
														mat-icon-button
													>
														<mat-icon
															class="menu-btn"
															fontIcon="mdi-dots-vertical"
															fontSet="mdi"
														></mat-icon>
													</button>
												</th>
											</tr>
											</thead>
											<tbody>
												@for (el of expandedElement.workflowItems; track $index) {
													<tr>
														@for (col of examsColumnsToDisplay; track $index) {
															@if (col.header === 'SPSSTATUS') {
																<td>
																	<mat-icon
																		[ngClass]="{
                              'mdi-spin':
                                getPatientStatusIcon(el) === 'mdi-timelapse'
                            }"
																		[ngStyle]="{
                              color: getIconColor(el),
                              fontSize: '20px !important'
                            }"
																		fontIcon="{{ getPatientStatusIcon(el) }}"
																		fontSet="mdi"
																	></mat-icon>
																</td>
															}
															@if (col.header === 'REPORTING_STATUS') {
																<td>
																	<button (click)="openReport(el)" mat-icon-button>
																		<mat-icon
																			[ngStyle]="{
                                color: getReportIconColor(el.reportingStatus),
                                fontSize: '20px !important'
                              }"
																			fontIcon="{{
                                getReportingStatusIcon(el.reportingStatus)
                              }}"
																			fontSet="mdi"
																		>
																		</mat-icon>
																	</button>
																</td>
															}
															@if (['PERFORMING_PHYSICIAN', 'TECHNICIAN'
															].includes(col.header)) {
																<td
																	[matTooltip]="el[col.attr]"
																	matTooltipPosition="right"
																>
																	{{ el[col.attr] | initials }}
																</td>
															}
															@if (['EXAM_START', 'EXAM_FINISH'].includes(
																col.header)) {
																<td>
																	{{ el[col.attr] | date : "HH:mm" }}
																</td>
															}
															@if (col.header === 'PATIENTARRIVAL') {
																<td>
																	{{ el[col.attr] | date : todayFormat }}
																</td>
															}
															@if (col.header === 'PAYMENT_STATUS') {
																<td>
                          <span
							  [class.animate]="el.paymentStatus === 'NOT_PAID'"
							  [ngStyle]="{
                              background: getPaymentStatusColor(
                                el.paymentStatus
                              ),
                              borderRadius: '50%'
                            }"
						  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
						  >
																</td>
															}
															@if (['TOTAL_AMOUNT', 'PAID_AMOUNT', 'DISCOUNT',
																'LEFT_AMOUNT'].includes(col.header)) {
																<td>
																	{{
																		el[col.attr]
																			| numeric
																			: {
																				numberFormat,
																				comma: true
																			}
																	}}
																</td>
															}
															@if (!specialFormat(col.header)) {
																<td>
																	{{ el[col.attr] }}
																</td>
															}
														}

														<!--actions-->
														<td class="fx-layout-row-nowrap">
															@if (generalSetting?.billingRequired &&
															profile.managePayment) {
																<button
																	(click)="addPayment(el)"
																	[disabled]="cannot('addPayment')"
																	[matTooltip]="'PAYMENT' | translate"
																	mat-icon-button
																>
																	<mat-icon
																		[class.btn-action-pay]="!cannot('addPayment')"
																		fontIcon="mdi-currency-usd"
																		fontSet="mdi"
																	></mat-icon>
																</button>
															}
															@if (!el.imagesAvailables) {
																<button
																	(click)="updateFromPacs(el)"
																	[matTooltip]="'MERGE_FROM_PACS' | translate"
																	mat-icon-button
																>
																	<mat-icon
																		class="toolbar-icon-gradient"
																		fontIcon="mdi-source-merge"
																		fontSet="mdi"
																	></mat-icon>
																</button>
															}
															@if (profile.viewPatientStudies !== 'NONE' &&
															defaultViewer) {
																<button
																	(click)="openStudy(el, defaultViewer, false)"
																	[disabled]="
                              !(
                                el['imagesAvailables'] &&
                                el['studyInstanceUID'] !== ''
                              )
                            "
																	[matTooltip]="
                              'OPEN_IN'
                                | translate
                                  : {
                                      viewer: defaultViewer.name
                                    }
                            "
																	mat-icon-button
																>
																	<mat-icon
																		[class.btn-action-view]="
                                el['imagesAvailables'] &&
                                el['studyInstanceUID'] !== ''
                              "
																		[fontIcon]="'mdi-monitor-dashboard'"
																		[fontSet]="'mdi'"
																		[style.color]="
                                el['imagesAvailables'] &&
                                el['studyInstanceUID'] !== ''
                                  ? '#00bcd4'
                                  : ''
                              "
																	></mat-icon>
																</button>
															}
															@if (almaActivated) {
																<button
																	(click)="openAlma(el)"
																	[disabled]="
                              !(
                                el['imagesAvailables'] &&
                                el['studyInstanceUID'] !== ''
                              )
                            "
																	[matTooltip]="
                              'OPEN_IN'
                                | translate
                                  : {
                                      viewer: 'FireWS'
                                    }
                            "
																	[style.color]="
                              el['imagesAvailables'] &&
                              el['studyInstanceUID'] !== ''
                                ? '#f1704e'
                                : ''
                            "
																	mat-icon-button
																>
																	<mat-icon
																		fontIcon="mdi-check-circle"
																		fontSet="mdi"
																	></mat-icon>
																</button>
															}
															@if (profile.viewPatientStudies !== 'NONE' &&
															defaultViewer && viewerOpen) {
																<button
																	(click)="openStudy(el, defaultViewer, false, false)"
																	[disabled]="
                              !(
                                el['imagesAvailables'] &&
                                el['studyInstanceUID'] !== ''
                              )
                            "
																	[matTooltip]="
                              ('ADD_TO' | translate) + defaultViewer.name
                            "
																	mat-icon-button
																>
																	<mat-icon
																		[fontIcon]="'mdi-layers-plus'"
																		[fontSet]="'mdi'"
																		[style.color]="
                                el['imagesAvailables'] &&
                                el['studyInstanceUID'] !== ''
                                  ? '#E91E63'
                                  : ''
                              "
																	></mat-icon>
																</button>
															}

															<button
																[disabled]="el.old"
																[matMenuTriggerFor]="wfMenu2"
																mat-icon-button
															>
																<mat-icon
																	fontIcon="mdi-dots-horizontal"
																	fontSet="mdi"
																></mat-icon>
															</button>

															<mat-menu #wfMenu2="matMenu" [overlapTrigger]="true">
																<button
																	(click)="enterPatient(el)"
																	[disabled]="
                                cannot('authorizeExam') ||
                                !isGranted(el, 'authorizeExam')
                              "
																	mat-menu-item
																>
																	<mat-icon
																		fontIcon="mdi-login"
																		fontSet="mdi"
																	></mat-icon>
																	{{ "ENTER_PATIENT" | translate }}
																</button>
																<button
																	(click)="startExam(el)"
																	[disabled]="
                                cannot('startExam') ||
                                !isGranted(el, 'startExam')
                              "
																	mat-menu-item
																>
																	<mat-icon
																		fontIcon="mdi-play"
																		fontSet="mdi"
																	></mat-icon>
																	{{ "STARTEXAM" | translate }}
																</button>
																<button
																	(click)="finishExam(el)"
																	[disabled]="
                                cannot('finishExam') ||
                                !isGranted(el, 'finishExam')
                              "
																	mat-menu-item
																>
																	<mat-icon
																		fontIcon="mdi-stop"
																		fontSet="mdi"
																	></mat-icon>
																	{{ "FINISHEXAM" | translate }}
																</button>
																@if (canAssign(el)) {
																	<button (click)="assignPerformer(el)" mat-menu-item>
																		<mat-icon
																			fontIcon="mdi-account-arrow-right"
																			fontSet="mdi"
																		></mat-icon>
																		{{ "DELEGATE_TASK" | translate }}
																	</button>

																	<button
																		(click)="
                                assignPerformer(
                                  el,
                                  DelegationType.RE_ASSIGN_TASK
                                )
                              "
																		mat-menu-item
																	>
																		<mat-icon
																			fontIcon="mdi-account-convert"
																			fontSet="mdi"
																		></mat-icon>
																		{{ "RE_ASSIGN_TASK" | translate }}
																	</button>
																}
																<button
																	(click)="onCompleteExam(el)"
																	[disabled]="
                                cannot('completeExam') ||
                                !isGranted(el, 'completeExam')
                              "
																	mat-menu-item
																>
																	<mat-icon
																		fontIcon="mdi-checkbox-multiple-marked-circle-outline"
																		fontSet="mdi"
																	></mat-icon>
																	{{ "COMPLETE_EXAM" | translate }}
																</button>
																<button
																	(click)="onPatientLeave(el)"
																	[disabled]="
                                cannot('exitPatient') ||
                                !isGranted(el, 'exitPatient')
                              "
																	mat-menu-item
																>
																	<mat-icon
																		fontIcon="mdi-logout"
																		fontSet="mdi"
																	></mat-icon>
																	{{ "PATIENTLEAVE" | translate }}
																</button>
																<mat-divider></mat-divider>
																<button
																	(click)="debitStock(el)"
																	[disabled]="cannot('completeExam')"
																	mat-menu-item
																>
																	<mat-icon
																		fontIcon="mdi-store"
																		fontSet="mdi"
																	></mat-icon>
																	{{ "PHARMACY" | translate }}
																</button>
																<mat-divider></mat-divider>
																<button
																	(click)="generateReport(el)"
																	[disabled]="
                                cannot('editReport') ||
                                !isGranted(el, 'editReport') ||
                                el.reportingTaskId === 0
                              "
																	mat-menu-item
																>
																	<mat-icon
																		fontIcon="mdi-file"
																		fontSet="mdi"
																	></mat-icon>
																	{{ "NORMAL_REPORT" | translate }}
																</button>
																<button
																	(click)="openReport(el, true)"
																	[disabled]="
                                cannot('editReport') ||
                                !isGranted(el, 'editReport')
                              "
																	mat-menu-item
																>
																	<mat-icon
																		fontIcon="mdi-open-in-new"
																		fontSet="mdi"
																	></mat-icon>
																	{{ "REPORT_NEW_TAB" | translate }}
																</button>
																<mat-divider></mat-divider>
																<button
																	(click)="downloadBooklet(el)"
																	[disabled]="
                                cannot('printReport') ||
                                !isGranted(el, 'printBooklet')
                              "
																	mat-menu-item
																>
																	<mat-icon
																		fontIcon="mdi-cloud-download"
																		fontSet="mdi"
																	></mat-icon>
																	{{ "DOWNLOAD_BOOKLET" | translate }}
																</button>

																@if (generalSetting?.reportPrintMode === 'CHROME') {
																	@if (profile?.printReport !== 'NONE') {
																		<button
																			(click)="printReportingTask(el)"
																			[disabled]="
                                cannot('printReport') ||
                                !isGranted(el, 'printReport')
                              "
																			mat-menu-item
																		>
																			<mat-icon
																				fontIcon="mdi-printer"
																				fontSet="mdi"
																			></mat-icon>
																			{{ "PRINT_REPORT" | translate }}
																		</button>
																	}
																	@if (profile?.printBooklet !== 'NONE') {
																		<button
																			(click)="printBooklet(el)"
																			[disabled]="
                                cannot('printReport') ||
                                !isGranted(el, 'printBooklet')
                              "
																			mat-menu-item
																		>
																			<mat-icon
																				fontIcon="mdi-printer"
																				fontSet="mdi"
																			></mat-icon>
																			{{ "PRINT_BOOKLET" | translate }}
																		</button>
																	}
																}

																<ng-template>
																	<button
																		[disabled]="
                                  cannot('printReport') ||
                                  !isGranted(el, 'printReport')
                                "
																		[matMenuTriggerFor]="printersMenu3"
																		mat-menu-item
																	>
																		<mat-icon
																			fontIcon="mdi-printer"
																			fontSet="mdi"
																		></mat-icon>
																		{{ "PRINT_REPORT" | translate }}
																	</button>

																	<button
																		[disabled]="
                                  cannot('printReport') ||
                                  !isGranted(el, 'printBooklet')
                                "
																		[matMenuTriggerFor]="printersMenu4"
																		mat-menu-item
																	>
																		<mat-icon
																			fontIcon="mdi-printer"
																			fontSet="mdi"
																		></mat-icon>
																		{{ "PRINT_BOOKLET" | translate }}
																	</button>

																	<mat-menu #printersMenu3="matMenu">
																		@for (printer of printers; track $index) {
																			<button
																				(click)="
                                    printReportingTask(el, printer['label'])
                                  "
																				mat-menu-item
																			>
																				<mat-icon
																					fontIcon="mdi-printer"
																					fontSet="mdi"
																				></mat-icon>
																				{{ printer["label"] }}
																			</button>
																		}
																	</mat-menu>
																	<mat-menu #printersMenu4="matMenu">
																		@for (printer of printers; track $index) {
																			<button
																				(click)="printBooklet(el, printer['label'])"
																				mat-menu-item
																			>
																				<mat-icon
																					fontIcon="mdi-printer"
																					fontSet="mdi"
																				></mat-icon>
																				{{ printer["label"] }}
																			</button>
																		}
																	</mat-menu>
																</ng-template>

																<button
																	(click)="showPatientFolder(el)"
																	[disabled]="!can(el, 'viewPatientFolder')"
																	[matTooltip]="'PATIENT_FOLDER' | translate"
																	mat-menu-item
																>
																	<mat-icon
																		fontIcon="mdi-folder-account"
																		fontSet="mdi"
																	></mat-icon>
																	{{ "PATIENT_FOLDER" | translate }}
																</button>

																<mat-divider></mat-divider>
																<button (click)="sendHL7Report(el)" mat-menu-item>
																	<mat-icon
																		fontIcon="mdi-file-upload"
																		fontSet="mdi"
																	></mat-icon>
																	{{ "BROADCAST_HL7_REPORT" | translate }}
																</button>
																<mat-divider></mat-divider>

																@if (profile?.printRdvForExam !== 'NONE') {
																	<button
																		(click)="printRdvForResult(el)"
																		[disabled]="
                                !can(el, 'createRdvForResult') ||
                                !isGranted(el, 'createRdvForResult')
                              "
																		mat-menu-item
																	>
																		<mat-icon
																			fontIcon="mdi-printer"
																			fontSet="mdi"
																		></mat-icon>
																		{{ "RDV_FOR_RESULT" | translate }}
																	</button>
																}
																@if (profile && profile.manageExam !== 'NONE' &&
																notPaid(el)) {
																	<button (click)="deleteExam(el)" mat-menu-item>
																		<mat-icon
																			fontIcon="mdi-delete"
																			fontSet="mdi"
																		></mat-icon>
																		{{ "DELETE" | translate }}
																	</button>
																}
															</mat-menu>
														</td>
													</tr>
												}
											</tbody>
										</table>
									}
								</div>
							</mat-cell>
						</ng-container>

						<!-- Hidden Column -->
						<ng-container matColumnDef="hidden">
							<mat-header-cell *matHeaderCellDef></mat-header-cell>
							<mat-cell
								(click)="$event.stopPropagation()"
								*matCellDef="let row"
							>
								@if (!row.billed || hideForced) {
									<mat-icon
										(click)="hideExam(row)"
										[fontIcon]="row.hidden ? 'mdi-eye-off' : 'mdi-eye'"
										[matTooltip]="(row.hidden ? 'UNHIDE' : 'HIDE') | translate"
										[class.eye-off-gradient]="row.hidden"
										[class.eye-gradient]="!row.hidden"
										[style.cursor]="'pointer'"
										fontSet="mdi"
									></mat-icon>
								}
							</mat-cell>
							<mat-footer-cell *matFooterCellDef></mat-footer-cell>
						</ng-container>

						<!-- Expand Column -->
						<ng-container matColumnDef="expand">
							<mat-header-cell *matHeaderCellDef></mat-header-cell>
							<mat-cell *matCellDef="let row">
								<button mat-icon-button (click)="expandRow(row)"
										[matTooltip]="(row === expandedElement ? 'COLLAPSE' : 'EXPAND') | translate">
									<mat-icon
										[fontIcon]="row === expandedElement ? 'mdi-chevron-down' : 'mdi-chevron-right'"
										fontSet="mdi"
									></mat-icon>
								</button>

							</mat-cell>
							<mat-footer-cell *matFooterCellDef></mat-footer-cell>
						</ng-container>

						<!--Table-->

						@for (col of displayedColumns; track $index) {
							<ng-container
								[class.hidden]="col.hidden"
								matColumnDef="{{ col.label }}"
								[sticky]="(selectedView | async).name === 'WORKFLOW_VIEW' && ['patientName', 'procedureCode'].includes(col.label)">
								<mat-header-cell
									*matHeaderCellDef
									[disabled]="!col.sortable"
									[mat-sort-header]="col.sortField"
								>
									{{ col.header | translate }}
								</mat-header-cell>

								@switch (col.type) {
									@case ('DATE') {
										<mat-cell
											*matCellDef="let row"
											[ngStyle]="getColumnStyle(col.type, col, row)"
										>
											<span>{{ row[col.label] | date : todayFormat }}</span>
										</mat-cell>
										<mat-footer-cell *matFooterCellDef></mat-footer-cell>
									}
									@case ('BOOLEAN') {
										<mat-cell
											*matCellDef="let row"
											[ngStyle]="getColumnStyleDisplayMode(col.type, col, row)"
										>
											@switch (getColumnDisplayMode(col.header)) {
												@case ('ICON') {
													<mat-icon
														[fontIcon]="
                    getColumnFormattingIcon(col.header, row[col.label])
                  "
														[matTooltip]="
                    (col.label === 'billed' && row[col.label]
                      ? 'N° ' + row['billNumber']
                      : row[col.label]
                      ? 'YES'
                      : ''
                    ) | translate
                  "
														matTooltipPosition="left"
														[ngStyle]="
                    getColumnFormattingIconStyle(col.header, row[col.label])
                  "
														fontSet="mdi"
													></mat-icon>
												}
												@case ('BADGE') {
													<span
														[ngStyle]="
                    getColumnStyleDisplayMode(col.type, col, row, 'BADGE')
                  "
														class="ft-badge"
													>{{ (row[col.label] ? "YES" : "NO") | translate }}</span
													>
												}
												@default {
													{{ (row[col.label] ? "YES" : "NO") | translate }}
												}
											}
										</mat-cell>
										<mat-footer-cell *matFooterCellDef></mat-footer-cell>
									}
									@case ('STATUS') {
										<mat-cell
											*matCellDef="let row"
											[ngStyle]="getColumnStyleDisplayMode(col.type, col, row)"
										>
											@switch (getColumnDisplayMode(col.header)) {
												@case ('ICON') {
													@if (col.header !== 'REPORTING_STATUS' || row.count === 1) {
														<mat-icon
															(click)="col.header === 'REPORTING_STATUS' ? openReport(row) : null"
															[fontIcon]="col.header === 'CONNECTED'? getConnectedIcon(col.header, row[col.label]): getColumnFormattingIcon(col.header, row[col.label])"
															[matTooltip]="row[col.label] | translate"
															matTooltipPosition="left"
															[ngStyle]="getColumnFormattingIconStyle(col.header, row[col.label])"
															[style.cursor]="col.header === 'REPORTING_STATUS' ? 'pointer' : ''"
															fontSet="mdi"
														></mat-icon>
													}
												}
												@case ('BADGE') {
													<span
														[ngStyle]="getColumnBooleanBadgeStyle(col.header, row[col.label])"
														class="ft-badge">{{ row[col.label] | translate }}</span>
												}
												@default {
													{{ row[col.label] | translate }}
												}
											}
										</mat-cell>
										<mat-footer-cell *matFooterCellDef></mat-footer-cell>
									}
									@case ('NUMERIC') {
										<mat-cell
											*matCellDef="let row"
											[ngStyle]="getColumnStyle(col.type, col, row)"
										>
											{{
												row
													| sumWorkItemBy : col.label
													| numeric
													: {
														numberFormat,
														comma: col.label != "count"
													}
											}}
										</mat-cell>
										<mat-footer-cell *matFooterCellDef>
                <span style="width: 100%">{{
						calculateSum(col.label, col.label !== "count")
					}}</span>
										</mat-footer-cell>
									}
									@default {
										@if (col.unit === 'ICON') {
											<mat-cell *matCellDef="let row">
												@if (row[col.label]) {
													<mat-icon
														[fontIcon]="'mdi-message'"
														[matTooltipClass]="'alert-tooltip'"
														[matTooltip]="row[col.label]"
														[ngStyle]="{
                    background: 'linear-gradient(45deg, black, #F44336, white)',
                    '-webkit-background-clip': 'text',
                    '-webkit-text-fill-color': 'transparent'
                  }"
														fontSet="mdi"
													></mat-icon>
												}
											</mat-cell>
										} @else {
											<mat-cell
												*matCellDef="let row"
												[ngStyle]="getColumnStyle(col.type, col, row)"
											>
												@if (col.label === 'patientName') {
													<div
														class="fx-layout-row-nowrap fx-content-space-between fx-items-center"
													>
														<a
															[routerLink]="'/patients/folder/' + row.patientId"
															class="ft-patient-link"
														>{{ row[col.label] }}</a
														>

														@if (row.smsSent) {
															<mat-icon
																[matTooltip]="row.smsContent"
																[style.color]="'#FF5722'"
																fontIcon="mdi-email-check"
																fontSet="mdi"
															></mat-icon>
														}
													</div>
												} @else {
													<span
														class="fx-ellipsis"
														[matTooltip]="
                    [
                      'insuranceName',
                      'procedureCode',
                      'referringPhysician',
                      'sideEffects'
                    ].includes(col.label)
                      ? (row[col.label] | translate)
                      : null
                  "
													>{{ row[col.label] | translate }}</span
													>
												}
											</mat-cell>
										}
										<mat-footer-cell *matFooterCellDef
										><span style="width: 100%">{{
												calculateTotal(col.label)
											}}</span>
										</mat-footer-cell>
									}
								}
							</ng-container>
						}

						<!--actions-->
						<ng-container matColumnDef="action" [stickyEnd]="true">
							<mat-header-cell *matHeaderCellDef>
								<div
									class="fx-layout-row fx-content-end fx-items-center"
									style="width: 100%"
								>
									<span class="fx-grow-1"></span>

									<button
										[matMenuTriggerFor]="menu"
										[matTooltip]="'TABLE_COLUMNS' | translate"
										mat-icon-button
										matTooltipPosition="left"
									>
										<mat-icon
											class="table-custom-column-icon"
											fontIcon="mdi-table-cog"
											fontSet="mdi"
										></mat-icon>
									</button>

									<button
										(click)="drawer.toggle()"
										[matTooltip]="'CONDITIONAL_FORMATTING' | translate"
										matTooltipPosition="left"
										mat-icon-button
									>
										<mat-icon
											class="toolbar-icon-gradient"
											fontIcon="mdi-cog"
											fontSet="mdi"
										></mat-icon>
									</button>
								</div>
							</mat-header-cell>
							<mat-cell
								(click)="$event.stopPropagation()"
								*matCellDef="let row"
							>
								<div
									class="fx-layout-row-nowrap fx-content-end fx-items-center"
									style="width: 100%"
								>
									<mat-menu #viewerMenu="matMenu" [overlapTrigger]="true">
										@for (viewer of viewers; track $index) {
											<button
												(click)="openStudy(row, viewer, true)"
												mat-menu-item
											>
												{{ viewer.name }}
											</button>
										}
									</mat-menu>

									<mat-menu #wfMenu="matMenu" [overlapTrigger]="true">
										<button
											(click)="newExam(row)"
											[disabled]="profile && profile['scheduleExam'] === 'NONE'"
											mat-menu-item
										>
											<mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
											{{ "NEW_EXAM" | translate }}
										</button>

										@if (eFactActivated()) {
											<button mat-menu-item (click)="generateEfactUrl(row)">
												<mat-icon fontIcon="mdi-link" fontSet="mdi"></mat-icon>
												{{ "eFact" | translate }}
											</button>
										}
										@if (eHealthBoxActivated()) {
											<button mat-menu-item (click)="generateEHealthBoxUrl(row)">
												<mat-icon fontIcon="mdi-link" fontSet="mdi"></mat-icon>
												{{ "eHealthBox" | translate }}
											</button>
										}

										<mat-divider></mat-divider>
										<button
											(click)="enterPatient(row)"
											[disabled]="
                        cannot('authorizeExam') ||
                        !isGranted(row, 'authorizeExam')
                      "
											mat-menu-item
										>
											<mat-icon fontIcon="mdi-login" fontSet="mdi"></mat-icon>
											{{ "ENTER_PATIENT" | translate }}
										</button>

										@if (row.count === 1) {
											<button
												(click)="startExam(row)"
												[disabled]="
                        cannot('startExam') || !isGranted(row, 'startExam')
                      "
												mat-menu-item
											>
												<mat-icon fontIcon="mdi-play" fontSet="mdi"></mat-icon>
												{{ "STARTEXAM" | translate }}
											</button>

											<button
												(click)="finishExam(row)"
												[disabled]="
                        cannot('finishExam') || !isGranted(row, 'finishExam')
                      "
												mat-menu-item
											>
												<mat-icon fontIcon="mdi-stop" fontSet="mdi"></mat-icon>
												{{ "FINISHEXAM" | translate }}
											</button>

											<button
												(click)="onCompleteExam(row)"
												[disabled]="
                        cannot('completeExam') ||
                        !isGranted(row, 'completeExam')
                      "
												mat-menu-item
											>
												<mat-icon
													fontIcon="mdi-checkbox-multiple-marked-circle-outline"
													fontSet="mdi"
												></mat-icon>
												{{ "COMPLETE_EXAM" | translate }}
											</button>

											<button
												(click)="onPatientLeave(row)"
												[disabled]="
                        cannot('exitPatient') || !isGranted(row, 'exitPatient')
                      "
												mat-menu-item
											>
												<mat-icon fontIcon="mdi-logout" fontSet="mdi"></mat-icon>
												{{ "PATIENTLEAVE" | translate }}
											</button>
											<mat-divider></mat-divider>

											<button
												(click)="debitStock(row)"
												[disabled]="cannot('completeExam')"
												mat-menu-item
											>
												<mat-icon fontIcon="mdi-store" fontSet="mdi"></mat-icon>
												{{ "PHARMACY" | translate }}
											</button>
										}

										<mat-divider></mat-divider>

										@if (row.count === 1) {
											<button
												(click)="generateReport(row)"
												[disabled]="
                        cannot('editReport') ||
                        !isGranted(row, 'editReport') ||
                        row.reportingTaskId === 0
                      "
												mat-menu-item
											>
												<mat-icon fontIcon="mdi-file" fontSet="mdi"></mat-icon>
												{{ "NORMAL_REPORT" | translate }}
											</button>

											<button
												(click)="openReport(row, true)"
												[disabled]="
                        cannot('editReport') || !isGranted(row, 'editReport')
                      "
												mat-menu-item
											>
												<mat-icon
													fontIcon="mdi-open-in-new"
													fontSet="mdi"
												></mat-icon>
												{{ "REPORT_NEW_TAB" | translate }}
											</button>
										}
										@if (canAssign(row)) {
											<button (click)="assignPerformer(row)" mat-menu-item>
												<mat-icon
													fontIcon="mdi-account-arrow-right"
													fontSet="mdi"
												></mat-icon>
												{{ "DELEGATE_TASK" | translate }}
											</button>

											<button
												(click)="
                        assignPerformer(row, DelegationType.RE_ASSIGN_TASK)
                      "
												mat-menu-item
											>
												<mat-icon
													fontIcon="mdi-account-convert"
													fontSet="mdi"
												></mat-icon>
												{{ "RE_ASSIGN_TASK" | translate }}
											</button>
										}

										<button (click)="addComment(row)" mat-menu-item>
											<mat-icon
												fontIcon="mdi-comment-alert"
												fontSet="mdi"
											></mat-icon>
											{{ "ADD_COMMENT" | translate }}
										</button>
										@if (profile?.printPaymentReceipt !== 'NONE') {
											<button (click)="printPaymentReceipt(row)" mat-menu-item>
												<mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
												{{ "PAYMENT_RECEIPT" | translate }}
											</button>
										}
										<mat-divider></mat-divider>
										@if (profile?.printAttestation !== 'NONE') {
											<button (click)="printAttestation(row)" mat-menu-item>
												<mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
												{{ "PRINT_ATTESTATION" | translate }}
											</button>
										}
										@if (row.count === 1 && profile?.printReport !== 'NONE') {
											<button
												(click)="downloadBooklet(row)"
												[disabled]="
                        cannot('printReport') || !isGranted(row, 'printBooklet')
                      "
												mat-menu-item
											>
												<mat-icon
													fontIcon="mdi-cloud-download"
													fontSet="mdi"
												></mat-icon>
												{{ "DOWNLOAD_BOOKLET" | translate }}
											</button>

											@if (generalSetting?.reportPrintMode === 'CHROME') {
												<button
													(click)="printReportingTask(row)"
													[disabled]="
                        cannot('printReport') || !isGranted(row, 'printReport')
                      "
													mat-menu-item
												>
													<mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
													{{ "PRINT_REPORT" | translate }}
												</button>

												<button
													(click)="printBooklet(row)"
													[disabled]="
                        cannot('printReport') || !isGranted(row, 'printBooklet')
                      "
													mat-menu-item
												>
													<mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
													{{ "PRINT_BOOKLET" | translate }}
												</button>
											}
										}

										<ng-template>
											@if (row.count === 1 && profile?.printReport !== 'NONE') {
												<button
													[disabled]="
                          cannot('printReport') ||
                          !isGranted(row, 'printReport')
                        "
													[matMenuTriggerFor]="printersMenu"
													mat-menu-item
												>
													<mat-icon
														fontIcon="mdi-printer"
														fontSet="mdi"
													></mat-icon>
													{{ "PRINT_REPORT" | translate }}
												</button>
											}

											<mat-menu #printersMenu="matMenu">
												@for (printer of printers; track $index) {
													<button
														(click)="printReportingTask(row, printer['label'])"
														mat-menu-item
													>
														<mat-icon
															fontIcon="mdi-printer"
															fontSet="mdi"
														></mat-icon>
														{{ printer["label"] }}
													</button>
												}
											</mat-menu>

											@if (row.count === 1 && profile?.printBooklet !== 'NONE') {
												<button
													[disabled]="
                          cannot('printReport') ||
                          !isGranted(row, 'printBooklet')
                        "
													[matMenuTriggerFor]="printersMenu2"
													mat-menu-item
												>
													<mat-icon
														fontIcon="mdi-printer"
														fontSet="mdi"
													></mat-icon>
													{{ "PRINT_BOOKLET" | translate }}
												</button>
											}

											<mat-menu #printersMenu2="matMenu">
												@for (printer of printers; track $index) {
													<button
														(click)="printBooklet(row, printer['label'])"
														mat-menu-item
													>
														<mat-icon
															fontIcon="mdi-printer"
															fontSet="mdi"
														></mat-icon>
														{{ printer["label"] }}
													</button>
												}
											</mat-menu>
										</ng-template>

										<button
											(click)="showPatientFolder(row)"
											[disabled]="!can(row, 'viewPatientFolder')"
											mat-menu-item
										>
											<mat-icon
												fontIcon="mdi-folder-account"
												fontSet="mdi"
											></mat-icon>
											{{ "PATIENT_FOLDER" | translate }}
										</button>

										<mat-divider></mat-divider>
										<button (click)="sendHL7Report(row)" mat-menu-item>
											<mat-icon
												fontIcon="mdi-file-upload"
												fontSet="mdi"
											></mat-icon>
											{{ "BROADCAST_HL7_REPORT" | translate }}
										</button>
										<button (click)="shareViaPortal(row)" mat-menu-item>
											<mat-icon
												fontIcon="mdi-monitor-share"
												fontSet="mdi"
											></mat-icon>
											{{ "portal_share" | translate }}
										</button>
										<mat-divider></mat-divider>

										@if (profile?.printRdvForExam !== 'NONE') {
											<button
												(click)="printRdvForResult(row)"
												[disabled]="profile?.createRdvForResult === 'NONE'"
												mat-menu-item
											>
												<mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
												{{ "RDV_FOR_RESULT" | translate }}
											</button>
										}

										<button (click)="displayPrintingHistory(row)" mat-menu-item>
											<mat-icon
												fontIcon="mdi-printer-eye"
												fontSet="mdi"
											></mat-icon>
											{{ "PRINTING_HISTORY" | translate }}
										</button>

										@if (profile && profile.manageExam !== 'NONE' && row.count
										=== 1 && notPaid(row)) {
											<button (click)="deleteExam(row)" mat-menu-item>
												<mat-icon fontIcon="mdi-delete" fontSet="mdi"></mat-icon>
												{{ "DELETE" | translate }}
											</button>
										}
									</mat-menu>

									@if (generalSetting?.billingRequired && !row.sent &&
									isAfterUpdate()) {
										<button
											(click)="sendToBilling(row)"
											[matTooltip]="'SYNC_BILLING' | translate"
											mat-icon-button
										>
											<mat-icon
												class="btn-action-sync"
												fontIcon="mdi-sync"
												fontSet="mdi"
											></mat-icon>
										</button>
									}
									@if (generalSetting?.billingRequired &&
									profile?.canDeletePayment) {
										<button
											(click)="addPayment(row)"
											[disabled]="cannot('addPayment')"
											[matTooltip]="'PAYMENT' | translate"
											mat-icon-button
										>
											<mat-icon
												class="btn-action-pay"
												fontIcon="mdi-currency-usd"
												fontSet="mdi"
											></mat-icon>
										</button>
									}
									@if (syngoviaActivated) {
										<button
											(click)="openInSyngovia(row)"
											[disabled]="
                      !(
                        row['imagesAvailables'] &&
                        row['studyInstanceUID'] !== '' &&
                        isCt(row['examType'])
                      )
                    "
											[matTooltip]="
                      'OPEN_IN'
                        | translate
                          : {
                              viewer: 'Syngo via'
                            }
                    "
											[style.color]="
                      row['imagesAvailables'] &&
                      row['studyInstanceUID'] !== '' &&
                      isCt(row['examType'])
                        ? '#6c71f3'
                        : ''
                    "
											mat-icon-button
										>
											<mat-icon
												fontIcon="mdi-check-circle"
												fontSet="mdi"
											></mat-icon>
										</button>
									}
									@if (almaActivated) {
										<button
											(click)="openAlma(row, true)"
											[disabled]="
                      !(
                        row['imagesAvailables'] &&
                        row['studyInstanceUID'] !== ''
                      )
                    "
											[matTooltip]="'OPEN_IN' | translate : { viewer: 'FireWS' }"
											[style.color]="
                      row['imagesAvailables'] && row['studyInstanceUID'] !== ''
                        ? '#f1704e'
                        : ''
                    "
											mat-icon-button
										>
											<mat-icon
												fontIcon="mdi-check-circle"
												fontSet="mdi"
											></mat-icon>
										</button>
									}
									@if (profile.viewPatientStudies !== 'NONE' && defaultViewer) {
										<button
											(click)="openStudy(row, defaultViewer, true)"
											[disabled]="
                      !(
                        row['imagesAvailables'] &&
                        row['studyInstanceUID'] !== ''
                      )
                    "
											[matTooltip]="
                      'OPEN_IN'
                        | translate
                          : {
                              viewer: defaultViewer.name
                            }
                    "
											mat-icon-button
										>
											<mat-icon
												[class.btn-action-view]="
                        row['imagesAvailables'] &&
                        row['studyInstanceUID'] !== ''
                      "
												[fontIcon]="'mdi-monitor-dashboard'"
												[fontSet]="'mdi'"
												[style.color]="
                        row['imagesAvailables'] &&
                        row['studyInstanceUID'] !== ''
                          ? '#00bcd4'
                          : ''
                      "
											></mat-icon>
										</button>
									}
									@if (profile.viewPatientStudies !== 'NONE' &&
									isRemoteEyeViewer()) {
										<button
											(click)="openStudy(row, 'RELite', true)"
											[disabled]="!(row['studyInstanceUID'] !== '')"
											[matTooltip]="'OPEN_IN' | translate : { viewer: 'RELite' }"
											mat-icon-button
										>
											<mat-icon
												[class.btn-action-view]="row['studyInstanceUID'] !== ''"
												[fontIcon]="'mdi-monitor-dashboard'"
												[fontSet]="'mdi'"
												[style.color]="
                        row['studyInstanceUID'] !== '' ? '#096aa0' : ''
                      "
											></mat-icon>
										</button>
									}
									@if (profile.viewPatientStudies !== 'NONE' && viewerOpen) {
										<button
											(click)="openStudy(row, 'RELite', true, false)"
											[disabled]="row['studyInstanceUID'] === ''"
											[matTooltip]="('ADD_TO' | translate) + defaultViewer.name"
											mat-icon-button
										>
											<mat-icon
												[class.btn-action-view]="row['studyInstanceUID'] !== ''"
												[fontIcon]="'mdi-layers-plus'"
												[fontSet]="'mdi'"
												[style.color]="
                        row['studyInstanceUID'] !== '' ? '#E91E63' : ''
                      "
											></mat-icon>
										</button>
									}
									@if (profile.viewPatientStudies !== 'NONE' &&
									viewers.length) {
										<button
											[disabled]="
                      !(
                        row['imagesAvailables'] &&
                        row['studyInstanceUID'] !== ''
                      )
                    "
											[matMenuTriggerFor]="viewerMenu"
											mat-icon-button
										>
											<mat-icon
												[class.btn-action-view-other]="
                        row['imagesAvailables'] &&
                        row['studyInstanceUID'] !== ''
                      "
												fontIcon="mdi-image-multiple"
												fontSet="mdi"
											></mat-icon>
										</button>
									}

									<button
										[disabled]="row['old']"
										[matMenuTriggerFor]="wfMenu"
										mat-icon-button
									>
										<mat-icon
											fontIcon="mdi-dots-horizontal"
											fontSet="mdi"
										></mat-icon>
									</button>
								</div>
							</mat-cell>
							<mat-footer-cell *matFooterCellDef></mat-footer-cell>
						</ng-container>

						<!--header-->
						<mat-header-row
							*matHeaderRowDef="columnsToDisplay; sticky: true"
						></mat-header-row>

						<mat-row
							[@rowsAnimation]="row"
							*matRowDef="let row; columns: columnsToDisplay"
							[class.hide]="row.hidden"
							[ngStyle]="getRowFormattingStyle(row)"
						></mat-row>

						<mat-row
							*matRowDef="let row; columns: ['expandedRow']"
							[class.visible]="row === expandedElement"
							class="detail-row"
						></mat-row>
						<mat-footer-row
							*matFooterRowDef="columnsToDisplay; sticky: true"
							[class.hidden-footer]="resultsLength === 0"
							[style.min-height.px]="32"
						></mat-footer-row>
					</mat-table>
				</div>
				<mat-paginator
					class="fx-paginator"
					[length]="resultsLength"
					[pageIndex]="0"
					[pageSizeOptions]="[20, 50, 100, 150, 200]"
					[pageSize]="defaultPageSize"
					[showFirstLastButtons]="true"
				>
				</mat-paginator>
			</div>
		</mat-drawer-content>
	</mat-drawer-container>
</mat-card>

@if (hiddenUser()) {
	<div class="ft-states fx-layout-row-nowrap fx-gap-4">
		<div class="total-hidden">
			Caché: {{ totalHidden }} {{ currencyFormat }} |
			{{ totalExamsHidden }} examens
		</div>
		<div class="turnover">
			CA: {{ turnover }} {{ currencyFormat }} | {{ totalExams }} examens.
		</div>
	</div>
}
