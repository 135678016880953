import {
    BehaviorSubject,
    merge, filter,
    of as observableOf,
    Subscription,
} from "rxjs";

import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    first,
    map,
    switchMap,
    tap,
} from "rxjs/operators";
import {
    AfterViewInit,
    Component, DestroyRef,
    ElementRef, inject,
    OnInit, TemplateRef, viewChild,
    ViewChild,
} from "@angular/core";
import {MatPaginator} from "@angular/material/paginator";
import {MatSnackBar, MatSnackBarRef} from "@angular/material/snack-bar";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {WorkflowService} from "./workflow.service";
import {EXAMS_COLS, PAYMENT_VIEW_COLUMNS} from "./table-conf";
import {
    ColumnDataType, DEFAULT_SEARCH_CONFIG,
    DelegationType,
    DisplayMode,
    FormatRule,
    FormattingRule,
    GeneralSettingDTO,
    PatientWorkflow,
    PaymentDTO,
    Profile,
    QueryParam,
    RendezVous,
    TableColumn,
    TableConfig,
    TableView,
    TargetDocument,
    ViewerDTO,
    WorkflowFilter,
    WorkflowItem,
    WorkflowTableView,
    WorkItemSubtotal,
} from "../model";
import {ActivatedRoute, Router} from "@angular/router";

import {
    findIndex,
    get,
    map as _map,
    remove,
    set as _set,
    sortBy,
    union,
} from "lodash";
import {FormBuilder, FormGroup} from "@angular/forms";
import moment from "moment";
import {
    DateUtils,
    getIconColor,
    getPatientStatusIcon,
    getReportIconColor,
    getReportingStatusIcon,
    loadRLEScripts,
    reorderTableColumns,
    StringUtils,
} from "../utils";
import {TranslateService} from "@ngx-translate/core";
import {
    checkCondition,
    CommentsComponent,
    CompleteExamFormComponent,
    DeleteConfirmComponent,
    getDisplayStyle,
    groupWorkflowData,
    hasPermission,
    LocalStorageService,
    PacsSearchComponent,
    PatientArrivedComponent,
    paymentColor,
    PerformerAssignComponent,
    PrescriptionComponent,
    RdvPrintComponent,
    SharedService,
    specialAttributes,
    StockMovementComponent,
    TableViewComponent,
    waitingDuration,
} from "../shared";
import {SchedulerService} from "../scheduler/scheduler.service";
import {ReportingService} from "../reporting/reporting.service";
import {SettingService} from "../setting/setting.service";
import {rowsAnimation} from "../animations";
import {WsService} from "../ws.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import FileSaver from "file-saver";
import numeral from "numeral";
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from "@angular/animations";
import {AppConfigService} from "../app-config.service";
import {PaymentFormComponent} from "../shared/payment-form/payment-form.component";
import {TasksListComponent} from "../shared/tasks-list/tasks-list.component";
import {PrintingHistoryComponent} from "../shared/printing-history/printing-history.component";
import {MatDialog} from "@angular/material/dialog";
import {ExamSchedulerComponent} from "../scheduler/exam-scheduler/exam-scheduler.component";
import {SearchService} from "../shared/advanced-search/search.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

const WF_TABLE_CONFIG_NAME = "workflow";
const WF_PAGE_SIZE = "wf_page_size";
const EXAM_COLUMNS = "exams_cols";
const SUPER_USERS = ["mgmt_srv", "firethunder"];

declare let LPW: any;

@Component({
    selector: "ft-workflow",
    templateUrl: "./workflow.component.html",
    styleUrls: ["./workflow.component.scss"],
    animations: [
        rowsAnimation,
        trigger("detailExpand", [
            state(
                "collapsed",
                style({
                    height: "0px",
                    minHeight: "0",
                    visibility: "hidden",
                    zIndex: "-1",
                })
            ),
            state("expanded", style({height: "*"})),
            transition(
                "expanded <=> collapsed",
                animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
            ),
        ]),
    ],
})
export class WorkflowComponent implements OnInit, AfterViewInit {
    filterForm: FormGroup | undefined;
    selectedView = new BehaviorSubject<TableView>(
        new TableView(0, 0, "WORKFLOW_VIEW")
    );

    totalHidden: any;
    turnover: any;

    displayedColumns = [];
    columnsToDisplay = [];
    availableColumns = [];
    tableColumns: TableColumn[] = [];
    examsColumns: { header: string; attr: string; hidden: boolean }[] =
        EXAMS_COLS;
    examsColumnsToDisplay: { header: string; attr: string; hidden: boolean }[] =
        EXAMS_COLS;
    dataSource = new MatTableDataSource<PatientWorkflow>();
    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;

    osirixViewers: ViewerDTO[] = [];

    expandedElement: PatientWorkflow;

    @ViewChild("date", {static: false}) _date: ElementRef;
    @ViewChild("filter", {static: true}) _filter: ElementRef;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    newExamData = {
        spsStatus: "ARRIVED",
        resource: "n/a",
        patient: null,
        isr: null,
        selectedDateRange: {start: moment(), end: moment().add(15, "m")},
        editable: true,
        queryParam: null,
    };

    sorting: { active: string; direction: "asc" | "desc" };

    modalities: any[] = ["CR", "CT", "DX", "MG", "MR", "US", "XA"];
    technicians: any[];
    physicians: any[];
    private wf = new WorkflowFilter();

    private newWindow: any;
    private readonly user: any;
    profile: Profile;
    canViewConfData: boolean;
    private examStatuses: string[];
    private workflowFilterSubject: BehaviorSubject<WorkflowFilter> =
        new BehaviorSubject<WorkflowFilter>(new WorkflowFilter());
    private query: BehaviorSubject<string> = new BehaviorSubject<string>("");
    generalSetting: GeneralSettingDTO;
    meddreamViewer: ViewerDTO;
    viewers: ViewerDTO[] = [];
    workflowTableConfig: TableConfig;
    linesFormattingRules: FormattingRule[] = [];
    columnsFormattingRules: FormattingRule[] = [];
    styles = {};

    public totalExams: any;
    public defaultPageSize = 100;

    currencyFormat = "DH";
    syngoviaActivated = false;
    almaActivated = false;
    public totalExamsHidden: any;
    currentDate: any; //= moment().format( 'LLLL');
    views: TableView[] = [
        new TableView(0, 0, "WORKFLOW_VIEW"),
        new TableView(0, 0, "PAYMENTS"),
        new TableView(0, 0, "PARTIAL_PAYMENTS"),
    ];
    todayFormat = "HH:mm";
    defaultViewer: ViewerDTO;

    printers: any[] = [];
    public readonly numberFormat: string;
    totalAmount: WorkItemSubtotal = new WorkItemSubtotal();
    private subTotalSubject: BehaviorSubject<WorkItemSubtotal> =
        new BehaviorSubject(new WorkItemSubtotal());

    hideForced: boolean;

    protected readonly DelegationType = DelegationType;

    private static isConditionFilled(
        colDataType: ColumnDataType,
        formatRule: FormatRule,
        data: any,
        firstValue: any,
        secondValue: any
    ): boolean {
        return checkCondition(
            colDataType,
            formatRule,
            data,
            firstValue,
            secondValue
        );
    }

    private _billingColumns: string[] = [
        "paymentStatus",
        "totalAmount",
        "paidAmount",
        "leftAmount",
        "discount",
        "globalDiscount",
        "billed",
    ];

    trackById = (_: number, item: any): string => item.patientID;


    searchTemplate = viewChild.required<TemplateRef<any>>('searchTemplate');

    private _destroyRef = inject(DestroyRef);

    constructor(
        private workflowService: WorkflowService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private scheduler: SchedulerService,
        private localStorage: LocalStorageService,
        private setting: SettingService,
        private _config: AppConfigService,
        private shared: SharedService,
        private wsService: WsService,
        private translate: TranslateService,
        private fb: FormBuilder,
        private snack: MatSnackBar,
        private reportingService: ReportingService,
        public searchService: SearchService
    ) {

        this._destroyRef.onDestroy(() => {
            this.localStorage.setItem("last_filter", this.filterForm.getRawValue());
            this.searchService.searchInputConfig.set(DEFAULT_SEARCH_CONFIG);
        });

        setTimeout(() => {
            this.updateToolbarSearchTemplate();

            searchService.newSearchViewObs.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => this.updateToolbarSearchTemplate());
            searchService.genericSearchObs.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(value => this.filterForm.get('key').patchValue(value));
        });

        this.generalSetting = this._config.generalSetting;
        this.currencyFormat = this._config.generalSetting.currency;
        this.numberFormat = this._config.numberFormat;
        this.syngoviaActivated = this._config.syngoviaActivated;
        this.almaActivated = this._config.almaActivated;

        this.user = get(this.route.snapshot.data, "user");
        this.profile = get(this.user, "profile");
        this.canViewConfData = this.user.canViewConfidentialData;

        this.currentDate = moment().format(
            this._config.appLang === "en" ? "LL" : "LLL"
        );

        const userId = this.user.id;

        this.subTotalSubject.asObservable().pipe(takeUntilDestroyed(this._destroyRef)).subscribe((data: WorkItemSubtotal) => {
            if (data) this.totalAmount = data;
        });

        this.setting
            .getTableConfig(WF_TABLE_CONFIG_NAME, userId)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((_tableConfig) => {
                if (_tableConfig) {
                    this.workflowTableConfig = _tableConfig;

                    this.tableColumns = get(_tableConfig, "tableColumns", []).map(
                        (column: TableColumn) => {
                            if (this._billingColumns.includes(column.label))
                                column.available = this.profile?.managePayment;
                            if (["sent", "completedReportStatus"].includes(column.label))
                                column.available = false;
                            return column;
                        }
                    );

                    this.dispatchRules(_tableConfig.formattingRules);
                    this._updateGridColumns(this.tableColumns);
                }
            });

        localStorage.setItem("eid_api_url", this.generalSetting.scannerIdUrl ?? "");

        this.translate
            .get("EXAM_STATUSES")
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((res) => (this.examStatuses = res.split("_")));

        this.sorting = this.localStorage.getItem("wf_sorting") || {
            active: "id",
            direction: "asc",
        };
        this.defaultPageSize =
            Number(this.localStorage.getItem(WF_PAGE_SIZE)) || 50;

        this.examsColumns =
            this.generalSetting && this.generalSetting.billingRequired
                ? this.examsColumns
                : this.examsColumns.filter(
                    (col) =>
                        !["totalAmount", "paidAmount", "discount"].includes(col.attr)
                );
        this.examsColumnsToDisplay =
            this.generalSetting && this.generalSetting.billingRequired
                ? this.examsColumnsToDisplay
                : this.examsColumnsToDisplay.filter(
                    (col) =>
                        !["totalAmount", "paidAmount", "discount"].includes(col.attr)
                );

        this.createFilterForm();

        setTimeout(() => this.subscribeToWsTopic(), 2000);
    }

    private updateToolbarSearchTemplate() {
        this.searchService.searchInputConfig.set({
            placeholder: 'search.workflow',
            expandable: this.searchService.newSearchView(),
            hidden: false,
            template: this.searchTemplate()
        });
    }

    private _updateGridColumns(tableColumns: TableColumn[]): void {
        this._updateDisplayColumns(tableColumns);

        this.workflowTableConfig.tableColumns = tableColumns;
        this.workflowTableConfig.userId = this.user.id;
        this.setting
            .saveTableConfig(this.workflowTableConfig)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((_tableConfig) => {
                this.workflowTableConfig = _tableConfig;
                this.tableColumns = get(_tableConfig, "tableColumns", []);
                this._updateDisplayColumns(_tableConfig.tableColumns);
            });
    }

    private _updateDisplayColumns(tableColumns: TableColumn[]): void {
        this.availableColumns = sortBy(
            tableColumns.filter((it) => it.available),
            "order"
        );

        this.displayedColumns = this.availableColumns.filter(
            (tc: TableColumn) => !tc.hidden
        );
        this.columnsToDisplay = union(
            this.hiddenUser() ? ["hidden", "expand"] : ["expand"],
            _map(this.displayedColumns, "value"),
            ["action"]
        );
    }

    drop(event: CdkDragDrop<string[]>) {
        this.tableColumns = reorderTableColumns(
            this.tableColumns,
            event.previousIndex,
            event.currentIndex
        );
        this._updateGridColumns(this.tableColumns);
    }

    drop2(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.examsColumns, event.previousIndex, event.currentIndex);
        this.localStorage.setItem(EXAM_COLUMNS, this.examsColumns);

        this.examsColumnsToDisplay = this.examsColumns.filter((it) => !it.hidden);
    }

    toggleColumn(col: TableColumn) {
        this.tableColumns = this.tableColumns.map((c: TableColumn) => {
            if (c.label === col.label) c.hidden = !col.hidden;
            return c;
        });

        this._updateGridColumns(this.tableColumns);
    }

    toggleColumn2(col: any) {
        const idx = findIndex(this.examsColumns, {header: col.header});
        this.examsColumns[idx].hidden = !col.hidden;
        this.examsColumnsToDisplay = this.examsColumns.filter((it) => !it.hidden);
        this.localStorage.setItem(EXAM_COLUMNS, this.examsColumns);
    }

    printPaymentReceipt(row: PatientWorkflow | WorkflowItem): void {
        const snackBarRef = this.snack.open("Printing receipt ...", "", {
            duration: 10000,
        });

        const payment = new PaymentDTO();
        payment.paymentID = row.paymentID;
        payment.patientName = row.patientName;

        if (this.generalSetting?.receiptPrintMode === "CHROME") {
            this.scheduler
                .printPaymentReceipt(payment)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((_) => snackBarRef.dismiss());
        } else {
            this.scheduler.printCupsPaymentReceipt(payment).pipe(takeUntilDestroyed(this._destroyRef)).subscribe((ok) => {
                if (ok["status"] !== "ok") alert("Cannot print the receipt");
                snackBarRef.dismiss();
            });
        }
    }

    newExam(row: any) {
        this.scheduler.getPatientFullDTOById(row.patientId)
            .pipe(
                filter(patient => !!patient),
                switchMap(patient => this.dialog
                    .open(ExamSchedulerComponent, {
                        data: {
                            patient: patient,
                            isr: null,
                            selectedDateRange: {
                                start: moment(),
                                end: moment().add(15, "h"),
                            },
                            editable: true,
                            queryParam: null,
                            admissionNumber: row.admissionNumber,
                            accessionNumbers: row.workflowItems.map(
                                (it) => it.accessionNumber
                            ),
                            paymentID: row.paymentID,
                            panelClass: "exam-dialog",
                        },
                        disableClose: true,
                    })
                    .afterClosed()),
                takeUntilDestroyed(this._destroyRef),
            ).subscribe(res => {
            if (res) this.workflowFilterSubject.next(this.wf);
        });
    }

    createExamWithPayment(queryParam: QueryParam = null) {
        _set(this.newExamData, "queryParam", queryParam);
        this.dialog
            .open(ExamSchedulerComponent, {
                data: this.newExamData,
                disableClose: true,
                panelClass: "exam-dialog",
                position: {top: "16px"},
                minWidth: "50vw",
            })
            .afterClosed()
            .pipe(
                takeUntilDestroyed(this._destroyRef))
            .subscribe((res) => {
                if (res && res["isrId"]) this.finishExam({isrId: res.isrId});
            });
    }

    ngOnInit(): void {
        this.selectedView.subscribe((value) => this.changeColumns(value));
        this.examsColumns = this.localStorage.getItem(EXAM_COLUMNS) || EXAMS_COLS;

        setTimeout(() => {
            if (this.generalSetting && !this.generalSetting.billingRequired) {
                this.examsColumns = this.examsColumns.filter(
                    (it) => !["discount", "totalAmount"].includes(it.attr)
                );
                this.examsColumnsToDisplay = this.examsColumns.filter(
                    (it) => !it.hidden
                );
            }
            if (!this.profile.managePayment) {
                this.examsColumns = this.examsColumns.filter(
                    (it) => !["paidAmount", "totalAmount", "discount"].includes(it.attr)
                );
                this.examsColumnsToDisplay = this.examsColumns.filter(
                    (it) => !it.hidden
                );
            }
        });

        this._filter?.nativeElement.focus();
        this.getTableViews();
        this.getViewers();
        this.shared.getRadiologists().pipe(takeUntilDestroyed(this._destroyRef)).subscribe((data) => (this.physicians = data));
        this.shared.getPrinters().pipe(takeUntilDestroyed(this._destroyRef)).subscribe((data) => (this.printers = data));
    }

    printRdvForResult(row: any) {
        const rdv = new RendezVous(
            row.patientID,
            row.accessionNumber,
            row.patientName,
            moment().format("YYYY-MM-DD"),
            row.procedureCode,
            ""
        );

        this.dialog.open(RdvPrintComponent, {data: rdv});
    }

    public printReportingTask(row: any, printer?: string): void {
        const matSnackBarRef: MatSnackBarRef<any> = this.snack.open(
            this.translate.instant("PRINTING_IN_PROGRESS"),
            "",
            {duration: 10000}
        );

        if (this.generalSetting.reportPrintMode === "CHROME")
            this.reportingService
                .printSimpleReport(row.reportingTaskId, printer, 1)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((_) => matSnackBarRef.dismiss());
        else {
            this.reportingService
                .printCupsSimpleReport(row.reportingTaskId, printer, "1")
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((ok) => {
                    matSnackBarRef.dismiss();
                    if (ok["status"] !== "ok") console.log("Cannot print the report");
                    else
                        this.snack.open(this.translate.instant("FINALIZING_PRINTING"), "", {
                            duration: 3000,
                        });
                });
        }
    }

    public printBooklet(row: any, printer?: string): void {
        const matSnackBarRef: MatSnackBarRef<any> = this.snack.open(
            this.translate.instant("PRINTING_IN_PROGRESS"),
            "",
            {duration: 10000}
        );

        if (this.generalSetting.reportPrintMode === "CHROME")
            this.reportingService
                .printReport(row.reportingTaskId)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((_) => matSnackBarRef.dismiss());
        else {
            this.reportingService
                .printCupsReport(row.reportingTaskId, printer, 1)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((response) => {
                    if (response["status"] !== "ok") alert("Cannot print the booklet");
                    else {
                        matSnackBarRef.dismiss();
                        this.snack.open(this.translate.instant("FINALIZING_PRINTING"), "", {
                            duration: 4000,
                        });
                    }
                });
        }
    }

    showPatientFolder = (row: any) =>
        this.router.navigate(["/patients/folder", row.patientId]);

    getPatientStatusIcon(row: WorkflowItem): string {
        if (this.isPatientAngry(row)) return "mdi-emoticon-sad";
        return getPatientStatusIcon(row.patientStatus);
    }

    getReportingStatusIcon = (status: string): string =>
        getReportingStatusIcon(status);

    getIconColor(row: WorkflowItem): string {
        return this.isPatientAngry(row) ? "#f00" : getIconColor(row.patientStatus);
    }

    getReportIconColor = (status: string): string => getReportIconColor(status);

    getPaymentStatusColor = (status: string): string => paymentColor(status);

    openReport(row: WorkflowItem, newWind?: boolean): void {
        localStorage.setItem("wf", JSON.stringify(this.wf));
        if (!row.reportingTaskId) return;
        if (newWind) {
            if (this.newWindow && !this.newWindow.closed) {
                this.newWindow.focus();
                this.newWindow.location.pathname = `/reporting/report-edition/${row.reportingTaskId}`;
            } else {
                this.newWindow = window.open(
                    `/reporting/report-edition/${row.reportingTaskId}`,
                    row.reportingTaskId.toString(),
                    "toolbar=0,location=0,menubar=0,left"
                );

                this.newWindow.addEventListener(
                    "beforeunload",
                    () => (this.newWindow = null)
                );
            }
        } else
            this.router
                .navigateByUrl(`/reporting/report-edition/${row.reportingTaskId}`)
                .then(console.log);
    }

    launchOsirix(
        aeTitle: string,
        patientID: string,
        studyInstanceUID: string
    ): void {
        this.reportingService
            .launchOsirix(aeTitle, patientID, studyInstanceUID)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(console.log);
    }

    startExam(row: any): void {
        this.scheduler.startExamByAN(row.accessionNumber).pipe(takeUntilDestroyed(this._destroyRef)).subscribe((_) => {
            setTimeout(() => this.workflowFilterSubject.next(this.wf), 10000);
            this.snack.open(this.examStatuses[3], "OK", {duration: 2000});
        });
    }

    finishExam(row: any): void {
        this.scheduler.finishExam(row.accessionNumber)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                setTimeout(() => this.workflowFilterSubject.next(this.wf), 10000);
                this.snack.open(this.examStatuses[0], "OK", {duration: 2000});
            });
    }

    addPayment(row: WorkflowItem): void {
        this.dialog
            .open(PaymentFormComponent, {
                data: {
                    paymentID: row.paymentID,
                    patientID: row.patientID,
                    accessionNumber: row.accessionNumber,
                    patientName: row.patientName,
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                filter(value => !!value),
                switchMap(order => this.scheduler.orderPayment(order)),
                takeUntilDestroyed(this._destroyRef))
            .subscribe((res) => {
                if (res && res.id) {
                    this.snack.open(
                        this.translate.instant("NEW_PAYMENT_DONE"),
                        "OK",
                        {duration: 2000}
                    );
                }
            });
    }

    onCompleteExam(row: any): void {
        this.dialog
            .open(CompleteExamFormComponent, {
                data: {
                    item: row,
                    isrId: row.isrId,
                    canViewConfData: this.canViewConfData,
                },
            })
            .afterClosed()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((sps) => {
                if (sps) {
                    this.snack.open(this.examStatuses[1], "OK", {
                        duration: 2000,
                    });
                }
            });
    }

    onPatientLeave(row: any): void {
        this.scheduler.exitPatient(row.accessionNumber)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                setTimeout(() => this.workflowFilterSubject.next(this.wf), 10000);
                this.snack.open(this.examStatuses[2], "OK", {duration: 2000});
            });
    }

    createPrescription(row: any): void {
        const patient = {
            patientName: row.patientName,
            patientID: row.patientID,
        };
        const physician = {physician: this.user.fullName};
        this.dialog
            .open(PrescriptionComponent, {
                data: {patient, physician},
                width: "60%",
            })
            .afterClosed()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((res) => console.log(res));
    }

    enterPatient(row: any): void {
        this.scheduler.getISRByAN(row.accessionNumber)
            .pipe(
                filter(value => !!value),
                switchMap(isr => this.dialog
                    .open(PatientArrivedComponent, {
                        data: {isr: isr, canViewConfData: this.canViewConfData},
                        width: "600px",
                    })
                    .afterClosed().pipe(
                        filter(value => !!value),
                        switchMap(isr => this.scheduler.markPatientAsArrived(isr))
                    )),
                takeUntilDestroyed(this._destroyRef)
            ).subscribe(() => {
            setTimeout(() => this.workflowFilterSubject.next(this.wf), 10000);
            this.snack.open(
                this.translate.instant("PATIENT_AUTHORIZED"),
                "Ok",
                {duration: 2000}
            );
        });
    }

    can = (row: any, action: string): boolean =>
        (this.profile[action] !== "NONE" && !row.confidential) ||
        this.canViewConfData;
    cannot = (action: string): boolean => this.profile[action] === "NONE";

    isGranted = (row: WorkflowItem, status: string): boolean =>
        hasPermission(status, row);

    private buildQuery(): void {
        this.workflowFilterSubject
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((wf: WorkflowFilter) => {
                const _query = [
                    wf.key.replace("@", ""),
                    wf.dateRange,
                    wf.technicianId,
                    this.profile.manageExam === "OWN" ? this.user.id : wf.physicianId,
                    wf.patientStatuses,
                    wf.reportStatuses,
                    wf.modalities,
                    wf.paymentStatuses,
                    wf.hasImages,
                ].join("@");
                this.query.next(_query);
            });
    }

    columnFormattingRules(header: string): FormattingRule[] {
        return this.columnsFormattingRules
            ? this.columnsFormattingRules.filter((it) => it.targetColumn === header)
            : [];
    }

    private createFilterForm(): void {
        this.filterForm = this.fb.group({
            key: "",
            startDate: new Date(),
            endDate: new Date(),
            period: "TODAY",
            modality: null,
            technicianId: null,
            physicianId: null,
            reportStatus: null,
            patientStatus: null,
            paymentStatus: null,
            hasImages: false,
        });

        this.filterForm.valueChanges
            .pipe(distinctUntilChanged(), takeUntilDestroyed(this._destroyRef))
            .subscribe((value) => this.buildWorkflowFilter(value));
    }

    changePeriod(): void {
        this.filterForm.get("period").patchValue("OT");
    }

    changeRange(e: any): void {
        const dateRange = DateUtils.PeriodDateRange(e.value);
        this.filterForm.patchValue(dateRange);
        this.wf.dateRange = `${dateRange.startDate.format(
            "YYYYMMDD"
        )}-${dateRange.endDate.format("YYYYMMDD")}`;
        this.workflowFilterSubject.next(this.wf);
    }

    deleteExam(wf: WorkflowItem): void {
        this.dialog
            .open(DeleteConfirmComponent)
            .afterClosed()
            .pipe(
                filter(value => !!value),
                switchMap(() => this.scheduler.deleteExams(wf.accessionNumber)),
                takeUntilDestroyed(this._destroyRef)
            ).subscribe((res) => {
            if (res)
                this.snack.open(this.translate.instant("EXAM_DELETED"), "Ok", {
                    duration: 2000,
                });
        });
    }

    private resetPaginator = () =>
        this.query.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            if (this.paginator) this.paginator.pageIndex = 0;
        });

    ngAfterViewInit(): void {
        if (this.isRemoteEyeViewer()) loadRLEScripts();
        this.buildQuery();
        this.resetPaginator();

        this.sort?.sortChange.subscribe((res) => {
            this.paginator.pageIndex = 0;
            this.localStorage.setItem("wf_sorting", res);
        });

        this._makeQuery();
    }

    private _makeQuery(): void {
        const observedFilters = [
            this.sort?.sortChange.asObservable(),
            this.paginator?.page.asObservable(),
            this.query.pipe(debounceTime(250)),
        ];

        merge(...observedFilters)
            .pipe(
                switchMap(() => {
                    this.isLoadingResults = true;
                    const _query = this.query.getValue();
                    this.localStorage.setItem(WF_PAGE_SIZE, this.paginator.pageSize);

                    return this.workflowService.getWorkflow(
                        this.paginator.pageSize,
                        this.paginator.pageIndex,
                        this.sort.active,
                        this.sort.direction,
                        _query
                    );
                }),
                tap((data) => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data["totalElements"];
                }),
                map((data) => data["content"] as WorkflowItem[]),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = true;
                    return observableOf([]);
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((data: WorkflowItem[]) => {
                this.dataSource.data = groupWorkflowData(data as WorkflowItem[]);

                if (SUPER_USERS.includes(this.user.username)) {
                    this.workflowService
                        .calculateCA(this.query.getValue().split("@")[1])
                        .subscribe((res) => {
                            this.turnover = Number(res.ca).toFixed(2);
                            this.totalHidden = Number(res.totalHidden).toFixed(2);
                            this.totalExams = res.totalExams;
                            this.totalExamsHidden = res.totalExamsHidden;
                        });
                }

                this.workflowService
                    .calculateSubTotals(this.query.getValue())
                    .pipe(takeUntilDestroyed(this._destroyRef))
                    .subscribe((value) => this.subTotalSubject.next(value));

                this.getLinesFormattingStyles();
            });

        const lastFilter = this.localStorage.getItem("last_filter");
        if (lastFilter)
            setTimeout(() =>
                this.filterForm.patchValue(this.localStorage.getItem("last_filter"))
            );
    }

    private changeColumns(view: TableView): void {
        if (view.name === "PAYMENTS" && this.profile?.managePayment)
            this.showPaymentsColumns();
        else if (view.name === "WORKFLOW_VIEW") this.showWorkflowColumns();
        else if (view.name === "PARTIAL_PAYMENTS" && this.profile?.managePayment)
            this.showLeftPaymentsColumns();
        else this.showCustomColumns(view);
    }

    addNewView(view?: TableView): void {
        this.dialog
            .open(TableViewComponent, {
                data: {
                    cols: this.tableColumns,
                    tableView: view || new WorkflowTableView(this.user.id),
                },
                autoFocus: true,
                disableClose: true,
                position: {top: "-3px"},
            })
            .afterClosed()
            .pipe(
                filter(value => !!value),
                tap(view => this.localStorage.setItem(view.name, null)),
                takeUntilDestroyed(this._destroyRef))
            .subscribe(() => this.updateViews());
    }

    saveTableConfig(tableConfig: TableConfig): void {
        this.setting
            .saveTableConfig(tableConfig)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((_tableConfig: TableConfig) => {
                if (_tableConfig) {
                    this.workflowTableConfig = _tableConfig;
                    this.dispatchRules(_tableConfig.formattingRules);

                    this.getLinesFormattingStyles();
                }
            });
    }

    getColumnStyle(
        colType: ColumnDataType,
        column: TableColumn,
        row: PatientWorkflow | WorkflowItem
    ): any {
        if (!this.columnsFormattingRules) return;
        const rule = this.columnsFormattingRules.find(
            (it) => it.targetColumn === column.header
        );
        if (
            rule &&
            WorkflowComponent.isConditionFilled(
                colType,
                rule.formatRule,
                row[column.label],
                rule.primaryFormatValue,
                rule.secondaryFormatValue
            )
        )
            return getDisplayStyle(rule.formattingStyle);
    }

    getColumnBooleanTextStyle(
        header: string,
        cellValue: any,
        displayMode = "TEXT"
    ): any {
        const rules = this.columnFormattingRules(header);

        const rule = rules.find(
            (it) => it.primaryFormatValue === cellValue.toString()
        );
        const style = rule ? rule.formattingStyle : null;

        const displayStyle = getDisplayStyle(style);

        if (rule && rule.formattingStyle.displayMode === displayMode)
            return displayStyle;
    }

    editView(view: TableView): void {
        this.addNewView(view);
    }

    deleteView(view: TableView): void {
        this.setting.deleteTableView(view.id).subscribe((res) => {
            if (res) {
                this.updateViews();
                this.selectedView.next(this.views[0]);
            }
        });
    }

    assignPerformer(
        row: WorkflowItem,
        title: DelegationType = DelegationType.DELEGATE_TASK
    ): void {
        this.dialog.open(PerformerAssignComponent, {
            data: {
                task: {
                    id: row.reportingTaskId,
                    secondPerformerName: {
                        id: row.secondPerformingPhysicianId,
                    },
                },
                title: title,
            },
            minWidth: "380px",
        });
    }

    canAssign(row: WorkflowItem): boolean {
        return (
            (this.can(row, "editReport") &&
                this.user.username === row.performingPhysician) ||
            this.profile["editReport"] !== "NONE"
        );
    }

    addComment(row: WorkflowItem): void {
        this.dialog
            .open(CommentsComponent, {
                data: row.noteAlert,
                width: "400px",
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                filter(value => value && value !== 'dismiss'),
                switchMap(comments => this.reportingService.saveNoteAlert(row.reportingTaskId, comments)),
                takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                this.snack.open(
                    this.translate.instant('COMMENT_SAVED'),
                    'OK',
                    {duration: 2000}
                )
            });
    }

    generateReport(row: WorkflowItem): void {
        this.snack
            .open(
                this.translate.instant("NO_NORMAL_REPORT_ATTACHED"),
                this.translate.instant("OPEN_REPORT"),
                {duration: 3000}
            )
            .onAction()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                this.openReport(row, false);
            });
    }

    getConnectedIcon(header: string, cellValue: string): any {
        const rules = this.columnFormattingRules(header);
        const rule = rules.find((it) => it.targetColumn === header);
        return rule && cellValue ? rule.formattingStyle.icon : "";
    }

    private updateViews(): void {
        this.views = this.views.filter((view) => view.id === 0);
        this.getTableViews();
    }

    private getViewers(): void {
        this.setting.getViewers().subscribe((res) => {
            this.viewers = res.filter((it) => !it.defaultViewer);
            this.defaultViewer = res.filter((v) => v.defaultViewer)[0];
            this.osirixViewers = res.filter((v) => v.osirix);
            this.meddreamViewer = res.filter(
                (v) => v.name === "MEDDREAM" || v.name.toLowerCase().includes("pro")
            )[0];
        });
    }

    calculateSum(label: string, comma = false): string {
        if (label === "count") return String(this.resultsLength);
        return this.formatNumeral(get(this.totalAmount, label) || 0, comma);
    }

    isPatientAngry(row: WorkflowItem): boolean {
        return (
            row.patientStatus === "WAITING" &&
            this.generalSetting &&
            waitingDuration(row) > 45
        ); // todo : same in scheduler-manager.component
    }

    exportExcel(): void {
        this.workflowService
            .exportToExcel(this.query.getValue())
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((res) => {
                const blob = new Blob([res], {
                    type: "application/vnd.ms-excel",
                });
                const file = new File([blob], "workflow.xlsx", {
                    type: "application/vnd.ms-excel",
                });

                FileSaver.saveAs(file);
            });
    }

    formatNumeral(numValue: any, comma: boolean = false): any {
        return numeral(numValue).format(`${comma ? this.numberFormat : "0,0"}`);
    }

    public get viewerOpen(): boolean {
        return !!window["viewerWindow"];
    }

    openInOsirix(row, aeTitle, multiple: boolean): void {
        if (multiple)
            row.workflowItems
                .filter((w) => w.imagesAvailables && w.studyInstanceUID)
                .forEach((it) =>
                    this.launchOsirix(aeTitle, it.patientID, row.studyInstanceUID)
                );
        else this.launchOsirix(aeTitle, row.patientID, row.studyInstanceUID);
    }

    notPaid(row: any): boolean {
        return (
            ["NOT_PAID", "EXEMPT"].includes(row.paymentStatus) ||
            !this.generalSetting?.billingRequired
        );
    }

    openInWebViewer(
        row: any,
        viewer: ViewerDTO,
        replace: boolean = true,
        multiple: boolean
    ): void {
        if (multiple) {
            this.workflowService
                .findExamsHistory(row.patientID)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((history) => {
                    const studyInstanceUIDs =
                        history.studyInstanceUIDs ||
                        row.workflowItems
                            .filter((w) => w.imagesAvailables && w.studyInstanceUID)
                            .map((s) => s.studyInstanceUID)
                            .join(",");

                    this.openViewerAndReport(row, viewer, replace, studyInstanceUIDs);
                });
        } else {
            this.openViewerAndReport(row, viewer, replace, row.studyInstanceUID);
        }
    }

    updateFromPacs(el: WorkflowItem): void {
        this.dialog
            .open(PacsSearchComponent, {
                data: el.patientName.trim().split(" ")[0],
                minHeight: "100vh",
                maxHeight: "100vh",
                minWidth: "90vw",
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                filter(value => value?.key === "merge"),
                switchMap(res => this.shared.mergeExams(res.data, el.accessionNumber)),
                takeUntilDestroyed(this._destroyRef))
            .subscribe((value) => {
                if (!value)
                    this.snack.open(this.translate.instant("ALREADY_MERGED"), "", {
                        duration: 2000,
                    });
            });
    }

    openStudy = (
        row: any,
        viewer: any,
        multiple?: boolean,
        replace: boolean = true
    ) => this.openViewer(row, viewer, replace, multiple);

    hiddenUser(): boolean {
        return ["firethunder", "mgmt_srv"].includes(this.user.username);
    }

    hideExam(row: any): void {
        this.workflowService
            .hideExam(row.id)
            .subscribe((res) => res && this.workflowFilterSubject.next(this.wf));
    }

    queryPacs(): void {
        this.dialog
            .open(PacsSearchComponent, {
                data: "",
                disableClose: true,
                minHeight: "100vh",
                maxHeight: "100vh",
                height: "90vh",
            })
            .afterClosed()
            .subscribe((res) => {
                if (res && res.key === "new") this.createExamWithPayment(res.data);
            });
    }

    expandRow(row: PatientWorkflow): void {
        this.expandedElement = this.expandedElement === row ? null : row;
    }

    specialFormat(header: string): boolean {
        return specialAttributes(header);
    }

    openInSyngovia(row: any, multiple = false): void {
        this.openInViewer(row, multiple, this.openSyngo.bind(this));
    }

    openAlma(row: any, multiple = false): void {
        this.openInViewer(row, multiple, this.openInAlma.bind(this));
    }

    openInAlma(uids: string): void {
        const viewer = this.viewers.find((it: ViewerDTO) =>
            it.name?.toLowerCase()?.startsWith("alma")
        );
        if (viewer && uids) window.open(viewer.remotePath + uids, "_blank");
    }

    private openInViewer(
        row: any,
        multiple = false,
        callback?: (uids: string) => void
    ): void {
        if (multiple) {
            this.workflowService
                .findExamsHistory(row.patientID)
                .subscribe(async (history) => {
                    const studyInstanceUIDs =
                        history.studyInstanceUIDs ||
                        row.workflowItems
                            .filter((w) => w.imagesAvailables && w.studyInstanceUID)
                            .map((s) => s.studyInstanceUID)
                            .join(",");

                    this.openReport(
                        row.imagesAvailables
                            ? row.workflowItems.filter((it) => it.imagesAvailables)[0]
                            : row,
                        false
                    );
                    callback(studyInstanceUIDs);
                });
        } else {
            this.openReport(row, false);
            callback(row.studyInstanceUID);
        }
    }

    public isRemoteEyeViewer = (): boolean => this._config.isRemoteEyeViewer;

    public launchRELite(studyUIDs?: any): void {
        LPW.REInt.popupRELite({
            username: this._config.remoteEyeUsername,
            password: this._config.remoteEyePassword,
            accNumsList: "",
            studyUIDsList: studyUIDs,
            rows: 1,
            columns: 2,
        });
    }

    sendToBilling(row: PatientWorkflow): void {
        this.workflowService
            .syncWithBilling(row.accessionNumber)
            .subscribe(console.log);
    }

    isAfterUpdate(): boolean {
        return moment().isSameOrAfter(moment("2019-10-23", "YYYY-MM-DD"), "d");
    }

    debitStock(row: WorkflowItem | PatientWorkflow): void {
        console.log(row['stockMovementId'])
        this.dialog
            .open(StockMovementComponent, {data: row, disableClose: true})
            .afterClosed()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                this.workflowFilterSubject.next(this.wf);
            });
    }

    getColumnDisplayMode(header: string): DisplayMode {
        const rules = this.columnFormattingRules(header);
        const rule = rules[0];
        return rule ? rule.formattingStyle.displayMode : DisplayMode.TEXT;
    }

    getColumnFormattingIcon(header: string, cellValue: any): any {
        const rules = this.columnFormattingRules(header);
        const rule = rules.find(
            (it) => it.primaryFormatValue === cellValue.toString()
        );
        return rule && rule.primaryFormatValue === cellValue.toString()
            ? rule.formattingStyle.icon
            : "";
    }

    private buildWorkflowFilter = (value: any): void => {
        this.todayFormat =
            value.period === "TODAY" ? "HH:mm" : this._config.dateTimeFormat;

        const startDate = moment(value.startDate).isValid()
            ? moment(value.startDate)
            : moment().subtract(10, "year");
        const endDate = moment(value.endDate).isValid()
            ? moment(value.endDate)
            : moment().add(10, "d");

        const start = startDate.format("YYYYMMDD");
        const end = endDate.format("YYYYMMDD");

        this.currentDate =
            value.period === "OT"
                ? ""
                : start === end
                    ? startDate.format(this._config.appLang === "en" ? "LL" : "LLLL")
                    : DateUtils.formatRange(startDate, endDate, this._config.appLang);

        this.wf.key = value.key;
        this.wf.dateRange = `${start}-${end}`;
        this.wf.modalities = value.modality ? value.modality.join("-") : "ALL";
        this.wf.reportStatuses =
            value.reportStatus && value.reportStatus.length !== 0
                ? value.reportStatus.join("-")
                : "ALL";
        this.wf.technicianId = value.technicianId;
        this.wf.physicianId = value.physicianId;
        this.wf.hasImages = value.hasImages;
        this.wf.patientStatuses =
            value.patientStatus && value.patientStatus.length !== 0
                ? value.patientStatus.join("-")
                : "ALL";
        this.wf.paymentStatuses =
            value.paymentStatus && value.paymentStatus.length !== 0
                ? value.paymentStatus.join("-")
                : "ALL";

        this.workflowFilterSubject.next(this.wf);
    };

    isCt = (examType: string): boolean =>
        examType.includes("TDM") || examType.includes("IRM");

    private openViewerAndReport(
        row: any,
        viewer: any,
        replace: boolean,
        studyInstanceUIDs: string
    ): void {
        this.openReport(
            row.imagesAvailables && row.workflowItems
                ? row.workflowItems.filter((it) => it.imagesAvailables)[0]
                : row,
            false
        );

        if (viewer === "RELite") this.launchRELite(studyInstanceUIDs);
        else if (viewer.name.toLowerCase().includes("other"))
            this.launchThirdPartyViewer(viewer, studyInstanceUIDs);
        else this.openOrUpdateViewerWindow(row, viewer, replace, studyInstanceUIDs);
    }

    private launchThirdPartyViewer(viewer: ViewerDTO, studyUIDs: string) {
        window.open(viewer.host + viewer.remotePath + studyUIDs, "_blank");
    }

    private openOrUpdateViewerWindow(
        row: any,
        viewer: any,
        replace: boolean,
        studyInstanceUIDs: string
    ) {
        const params = `${viewer.name}_${studyInstanceUIDs}`;

        const viewerWindow = "viewerWindow";

        if (window[viewerWindow] && !window[viewerWindow].closed) {
            window[viewerWindow].focus();
            this.reportingService
                .openStudy(row.studyInstanceUID, this.user.username, replace)
                .subscribe();
        } else {
            window[viewerWindow] = window.open(
                `/external-viewer/study?param=${params}`,
                viewerWindow,
                "toolbar=0,location=0,menubar=0,left"
            );
            window[viewerWindow].addEventListener(
                "beforeunload",
                () => (window[viewerWindow] = null)
            );
        }
    }

    getColumnFormattingIconStyle(header: string, cellValue: any): any {
        const rules = this.columnFormattingRules(header);
        const rule = rules.find(
            (it) => it.primaryFormatValue === cellValue.toString()
        );
        if (rule) return getDisplayStyle(rule.formattingStyle);
    }

    getColumnBooleanBadgeStyle(header: string, cellValue: any): any {
        return this.getColumnBooleanTextStyle(header, cellValue, "BADGE");
    }

    getColumnStyleDisplayMode(
        colType: ColumnDataType,
        column: TableColumn,
        row: PatientWorkflow | WorkflowItem,
        displayMode: string = DisplayMode.TEXT
    ): any {
        if (!this.columnsFormattingRules) return;

        const rule = this.columnsFormattingRules.find(
            (it) => it.targetColumn === column.header
        );
        if (
            rule &&
            rule.formattingStyle.displayMode === displayMode &&
            WorkflowComponent.isConditionFilled(
                colType,
                rule.formatRule,
                row[column.label],
                rule.primaryFormatValue,
                rule.secondaryFormatValue
            )
        )
            return getDisplayStyle(rule.formattingStyle);
    }

    getRowFormattingStyle(row: WorkflowItem | PatientWorkflow): any {
        if (!this.linesFormattingRules) return;
        return this.styles[row.accessionNumber];
    }

    private getTableViews(): void {
        this.setting.getTableViews(this.user.id, "WORKFLOW").subscribe((views) => {
            const items = this.views.concat(views);
            if (this.generalSetting && !this.generalSetting.billingRequired)
                remove(items, {name: "PAYMENTS"});
            this.views = items;
        });
    }

    private showPaymentsColumns(): void {
        const columns = this.tableColumns.filter((col) =>
            PAYMENT_VIEW_COLUMNS.includes(col.value)
        );
        this._updateDisplayColumns(columns);

        this.filterForm.get("paymentStatus").patchValue([]);
    }

    private showWorkflowColumns(): void {
        this._updateDisplayColumns(this.tableColumns);
        this.filterForm.get("paymentStatus").patchValue([]);
    }

    private showCustomColumns(view: TableView): void {
        this._updateDisplayColumns(this.tableColumns);
    }

    private getLinesFormattingStyles(): void {
        if (!this.linesFormattingRules) return;

        this.linesFormattingRules.forEach((rule) => {
            const column = this.workflowTableConfig.tableColumns.find(
                (it) => it.header === rule.targetColumn
            );
            this.dataSource.data.forEach((row) => {
                if (
                    WorkflowComponent.isConditionFilled(
                        column.type,
                        rule.formatRule,
                        row[column.label],
                        rule.primaryFormatValue,
                        rule.secondaryFormatValue
                    )
                )
                    this.styles[row.accessionNumber] = getDisplayStyle(
                        rule.formattingStyle
                    );
            });
        });
    }

    private dispatchRules(defaultRules: FormattingRule[]): void {
        const rules = StringUtils.groupBy(defaultRules, "appliedTo");
        this.linesFormattingRules = rules["ROW"];
        this.columnsFormattingRules = rules["COLUMN"];
    }

    private showLeftPaymentsColumns(): void {
        const columns = this.tableColumns.filter((col) =>
            PAYMENT_VIEW_COLUMNS.includes(col.value)
        );
        this._updateDisplayColumns(columns);

        this.filterForm
            .get("paymentStatus")
            .patchValue(["PAID_PARTIALLY", "NOT_PAID"]);
    }

    calculateTotal(label: string): any {
        if (label === "patientName")
            return (
                "Total patients: " + this.formatNumeral(this.dataSource.data.length)
            );
    }

    private openInRadiant(viewer: ViewerDTO, row: any, multiple = false): void {
        const radiantUrl = viewer.remotePath + "n=pstv&v=0020000D&v=%22";
        if (multiple) {
            this.workflowService
                .findExamsHistory(row.patientID)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((history) => {
                    const studyInstanceUIDs =
                        history.studyInstanceUIDs ||
                        row.workflowItems
                            .filter((w) => w.imagesAvailables && w.studyInstanceUID)
                            .map((s) => s.studyInstanceUID)
                            .join("%22&n=pstv&v=0020000D&v=%22");

                    this.openReport(
                        row.imagesAvailables
                            ? row.workflowItems.filter((it) => it.imagesAvailables)[0]
                            : row,
                        false
                    );
                    open(radiantUrl + studyInstanceUIDs + "%22");
                });
        } else {
            this.openReport(row, false);
            open(radiantUrl + row.studyInstanceUID + "%22");
        }
    }

    private openSyngo(uids: string): void {
        this.workflowService.openSyngo(uids)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((res) => {
                if (res)
                    this.snack.open(this.translate.instant("SYNGO_OPENING"), "", {
                        duration: 4000,
                    });
                else
                    this.snack.open(this.translate.instant("SYNGO_OPENING_ERROR"), "", {
                        duration: 2000,
                    });
            });
    }

    private openViewer(
        row: any,
        viewer: any,
        replace: boolean = true,
        multiple: boolean = false
    ): void {
        if (viewer === "RELite") {
            if (multiple) {
                this.workflowService
                    .findExamsHistory(row.patientID)
                    .pipe(takeUntilDestroyed(this._destroyRef))
                    .subscribe((history) => {
                        const studyInstanceUIDs =
                            history.studyInstanceUIDs ||
                            row.workflowItems
                                .filter((w) => w.imagesAvailables && w.studyInstanceUID)
                                .map((s) => s.studyInstanceUID)
                                .join(",");

                        this.openViewerAndReport(row, viewer, replace, studyInstanceUIDs);
                    });
            } else {
                this.openViewerAndReport(row, viewer, replace, row.studyInstanceUID);
            }
        } else if (viewer.remotePath.startsWith("radiant"))
            this.openInRadiant(viewer, row, multiple);
        else if (!viewer.osirix)
            this.openInWebViewer(row, viewer, replace, multiple);
        else this.openInOsirix(row, viewer, multiple);
    }

    private subscribeToWsTopic(): Subscription {
        return this.wsService
            .observeTopic("workflow")
            .pipe(filter(value => (value.topic === "workflow" && value.response === "update")))
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe({
                next: () => this.workflowFilterSubject.next(this.wf),
                error: (err) => console.log(err),
                complete: () => console.log("complete and unsubscribe from ws workflow topic"),
            });
    }

    printAttestation(row: any): void {
        const matSnackBarRef = this.snack.open(
            this.translate.instant("PRINTING_IN_PROGRESS"),
            "",
            {duration: 10000}
        );
        this.shared
            .printAttestation(row.id)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((_) => matSnackBarRef.dismiss());
    }

    generateEfactUrl(row: any): void {
        const ans = [
            row.accessionNumber,
            ...row.workflowItems.map((it) => it.accessionNumber),
        ].join("@");
        this.scheduler
            .generateEfactUrl(ans)
            .pipe(first(), takeUntilDestroyed(this._destroyRef))
            .subscribe((efact) => open(efact.url));
    }

    generateEHealthBoxUrl(row: any): void {
        this.scheduler
            .generateEHealthBoxUrl(row.accessionNumber)
            .pipe(first(), takeUntilDestroyed(this._destroyRef))
            .subscribe((eHBox) => open(eHBox.url));
    }

    eFactActivated = () => this._config.eFactActivated;
    eHealthBoxActivated = () => this._config.eHealthBoxActivated;

    generateTasksList(): void {
        this.dialog.open(TasksListComponent);
    }

    displayPrintingHistory(row: any): void {
        this.dialog.open(PrintingHistoryComponent, {
            data: {
                targetDocuments: [
                    TargetDocument.THERMAL_TICKET,
                    TargetDocument.PAYMENT_RECEIPT,
                    TargetDocument.RDV_FOR_RESULT,
                    TargetDocument.ATTESTATION,
                    TargetDocument.A4_REPORT,
                    TargetDocument.BOOKLET,
                ],
                documentId: row.accessionNumber,
            },
        });
    }

    sendHL7Report(row: any): void {
        this.reportingService.sendHL7Report(row).pipe(takeUntilDestroyed(this._destroyRef)).subscribe((value) => {
            if (value)
                this.snack.open("Compte rendu diffusé", "", {duration: 2000});
        });
    }

    shareViaPortal(row: PatientWorkflow): void {
        this.reportingService
            .getReferringPhysicianEmail(row.accessionNumber)
            .pipe(
                filter(value => !!value),
                map(value => ({
                    study_uid: row.workflowItems
                        .map((it) => it.studyInstanceUID)
                        .join(","),
                    username: value["email"],
                    url_expiration_datetime: moment()
                        .add(1, "month")
                        .format("YYYY-MM-DD HH:mm:ss"),
                })),
                tap(value => console.log(value)),
                switchMap(shareRequest => this.reportingService.shareViaPortal(shareRequest)),
                takeUntilDestroyed(this._destroyRef))
            .subscribe((ok) => {
                if (ok)
                    this.snack.open(this.translate.instant("portal_shared"), "", {
                        duration: 3000,
                    });
            });
    }

    downloadBooklet(row: any): void {
        const filename = [
            row.patientName.trim().split(" ").join("_"),
            row.patientArrival.replaceAll(".", ":"),
        ].join("_");
        const matSnackBarRef = this.snack.open(
            this.translate.instant("DOWNLOADING"),
            "",
            {duration: 12000}
        );
        this.reportingService
            .downloadReport(row.reportingTaskId, filename)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((_) => matSnackBarRef.dismiss());
    }
}
